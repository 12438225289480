<template>
  <div class="blocks">
    <HomeHead></HomeHead>
    <div class="block">
      <!-- 头部 -->
      <div class="head">LP挖矿申请详情</div>

      <!-- 申请展示 -->
      <div class="bodys">
          <el-row class="padding6">
              <el-col :span="24">LP池交易对名称 ( Token Name ):</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenNameOne}}</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenNameTwo}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">LP池币种代码 ( Token Symbol ):</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenSymbolOne}}</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenSymbolTwo}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">LP池币种logo链接:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenLogoOne}}</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.tokenLogoTwo}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">合约地址:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.contractAddressOne}}</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.contractAddressTwo}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">项目简介:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">
                <p style="line-height:25px">{{ruleForm.projectIntroduction}}</p>
              </el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">币种官方网站:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.website}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">币种白皮书网站:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.whitePaperWebsite}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">项目在Github上的链接地址:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.githubWebsite}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">Twitter或Telegram链接:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.teleTwitterWebsite}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">您的Email:</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.email}}</el-col>
          </el-row>
          <el-row style="height:10px"></el-row>


          <el-row class="padding6">
              <el-col :span="24">其他联系方式 (手机或电话):</el-col>
          </el-row>
          <el-row class="padding6 bg border">
              <el-col :span="24">{{ruleForm.contactPhone}}</el-col>
          </el-row>

        <div class="tips">
          <p>申请审核需7个工作日，若审核通过，FAX审核团队将会与您电话沟通进行进一步评估。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let Web3 = require("web3");
import HomeHead from "../components/homehead.vue";
export default {
  components: {
    HomeHead,
  },
  data() {
    return {
      ruleForm: {},
      iquery: {
          "auditStatus":'',
          "applicantContractAddress":"",
          "pageNum":1,
          "pageSize":10
      }
    }
  },
  async created() {
    console.log('---------query----------',this.$route.query.idata);
    this.ruleForm = this.$route.query.idata
    // 获取钱包地址
    // let web3 = new Web3(window.ethereum);
    // let addres = await window.ethereum.enable();
    // this.iquery.applicantContractAddress = addres[0];
    // console.log("-----applicantContractAddress------", this.iquery.applicantContractAddress);
    // 获取申请状态码
    // this.iquery.auditStatus = this.$route.query.iquery
    // console.log('-------------auditStatus-----------',this.iquery.auditStatus);
  },
  mounted() {
    // let t = setInterval(() => {
    //   if(this.iquery.applicantContractAddress) {
    //     clearInterval(t)
    //   }
    //   this.getData()
    // }, 200);
    
  },
  methods: {
    // getData() {
    //   console.log("-----applicantContractAddress------", this.iquery.applicantContractAddress);

    //   this.$axios.post('lpApplication/page/list', this.iquery)
    //   .then(res => {
    //       console.log('----------res-----------',res);
    //       if(res.data.status ==200) {
    //           this.ruleForm = res.data.data.content[0]
    //         this.$message.success("提交成功!");
    //         return
    //       }
    //       this.$message.error("获取失败!");

    //   })
    // }
  },
};
</script>

<style lang="sass" scoped>
.blocks
  .block
    padding: 10px
    padding-bottom: 100px
    .head
      margin: 10px auto
      text-align: center
      font-size: 16px
    .bodys
      padding: 10px
      .padding6
        padding: 4px
      .color
        color: blue
      .bg
        background: #fff
      .border
        margin-bottom: 5px
        border:1px solid #d3dce6
        border-radius: 5px
      .body
        margin: 15px 0
      .submit
        display: flex
        align-items: center
        justify-content: space-between
      .tips
        padding: 20px 0
        p
          line-height: 20px
          color: red
</style>