<template>
	<div id="">
		<HomeHead>
		</HomeHead>

		<div class="padding" style="padding-bottom: 100px;">
			<div class="head bold">
				<p>关于FaxSwap和双子池流动性</p>
				<p>挖矿的开启公告</p>
			</div>
			<hr />
			<div>
				<h2 class="bold margin-top">尊敬的用户：</h2>
				<h2>经过严谨的技术开发部署，FaxChain的生态基础已初步搭建完成。同时后期技术迭代亦开始积极布局实施。FaxChain创始团队有信心、有能力做好生态建设与落地应用，力争成为逆时之下的价值投资明星。</h2>
				<h2>目前FaxSwap已于北京时间8月12日正式开启，同时Gemini双子池也于13日12点正式开挖，请各位用户互相转告。</h2>
				<h2 class="bold margin-top">具体交易和挖矿规则如下：</h2>
				<h2>1. 请在FaxWallet官网下载升级钱包，以后FaxWallet将会自动升级，FaxWallet是一个完全去中心化的轻钱包，所有私钥只存储在用户的物理设备上，请务必保存好私钥和助记词。</h2>
				<h2>2. FaxWallet目前支持Tron和FaxChain两条公链，社区可以将原有波场钱包私钥导入FaxWallet，无需创建新的波场钱包。</h2>
				<h2>3. 拥有TRX和FAX双链的钱包后，可以通过跨链桥将TRX链上的USDT兑换成FAX链上的WUSDT。</h2>
				<h2>4. 为确保数据精准无误，双子池挖矿开启3天后可以随意提取收益。由于FaxChain链上还未产生数据，所有交易数据信息将在7天后校准无误公开显示。</h2>
				<h2>5. 为了夯实双子池流动性，挖矿45天后开启自由撤出流动性。星际池流动性挖矿也将在45天后同时开启，届时FS可以自由挖矿和交易。</h2>
				<h2>6. FaxSwap所有交易手续费用于回购FS，将于星际池开启30天后开始回购，且将回购所得FS注入基金会Foundation开启质押分红。</h2>
				<h2>7. FaxSwap也将于Q4推出FAX量化理财、存币生息。</h2>
				<h2>8. 所有官方动态将显示在FaxSwap的公告栏内，敬请关注官网公告、教程锦集和FAQ。</h2>
				<h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
				<h2 class="bold">2021 年 8 月 13 日&emsp;</h2>
				<h2>&emsp;</h2>
				
				
			</div>
		</div>
	</div>
</template>

<script>
	import HomeHead from "../components/homehead.vue";
	export default {
		name:'FaxSwapCourse',
		components: {
		  HomeHead
		}
	}
</script>

<style>
	.head {
		text-align: center;
		font-size: 20px;
		padding: 30px 0;
	}
	p {
		padding: 5px 0;
	}

	.bold {
		font-weight: bold;
	}

	.padding {
		padding: 30px;
	}
	.margin-top{
		margin-top: 2em;
	}
	h2
	 {
		line-height: 3em;
	}
	h3{
		line-height: 2em;
	}
</style>