<template>
  <div id="">
    <HomeHead> </HomeHead>

    <div class="padding" style="padding-bottom: 100px">
      <div class="head bold">
        <p>FaxSwap新增DogMoon跨链和流动性挖矿公告</p>
      </div>
      <hr />
      <div>
        <h2 class="bold margin-top">尊敬的用户：</h2>
        <h2>
          FaxSwap现已开通DogMoon的跨链功能，用户可以在跨链桥中，从Heco跨链至FAX链。
          同时，DogMoon/FAX的流动性挖矿已开启，DogMoon/FAX矿池同时产出FAX和FS，其中FAX产量40万枚挖矿周期9个月，FS产量3000枚挖矿周期9个月。
        </h2>
        <h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
        <h2 class="bold">2021 年 11 月 12 日&emsp;</h2>
        <h2>&emsp;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
  name: "Notice2",
  components: {
    HomeHead,
  },
};
</script>

<style>
.label1 {
  margin-bottom: 4px;
  width: 90px;
  height: 12px;
}
.label2 {
  margin-left: -20px;
  width: 360px;
  height: 50px;
}
.head {
  text-align: center;
  font-size: 20px;
  padding: 30px 0;
}
p {
  padding: 5px 0;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 30px;
}
.margin-top {
  margin-top: 2em;
}
h2 {
  line-height: 3em;
}
h3 {
  line-height: 2em;
}
</style>