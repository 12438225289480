<template>
  <div id="">
    <HomeHead> </HomeHead>

    <div class="padding" style="padding-bottom: 100px">
      <div class="head bold">
        <p>FaxSwap新增TYT流动性挖矿公告</p>
      </div>
      <hr />
      <div>
        <h2 class="bold margin-top">尊敬的用户：</h2>
        <h2>
          FaxSwap将于10月27日13:18（UTC+8）新增天佑通宝TYT/FAX的流动性挖矿。
          TYT/FAX矿池同时产出DXC和FAX，其中DXC产量350万枚挖矿周期1年，FAX产量10万枚挖矿周期6个月。
        </h2>
        <h2 class="bold margin-top">
            TYT官网：
            <a href="http://www.tytcoin.cn">http://www.tytcoin.cn</a>
        </h2>
        <h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
        <h2 class="bold">2021 年 10 月 25 日&emsp;</h2>
        <h2>&emsp;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
  name: "Notice2",
  components: {
    HomeHead,
  },
};
</script>

<style>
.label1 {
  margin-bottom: 4px;
  width: 90px;
  height: 12px;
}
.label2 {
  margin-left: -20px;
  width: 360px;
  height: 50px;
}
.head {
  text-align: center;
  font-size: 20px;
  padding: 30px 0;
}
p {
  padding: 5px 0;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 30px;
}
.margin-top {
  margin-top: 2em;
}
h2 {
  line-height: 3em;
}
h3 {
  line-height: 2em;
}
</style>