<template>
  <div style="padding-bottom: 3.6rem;">
    <HomeHead></HomeHead>
    <!-- <Head>
      <template v-slot:connect>
        <div class="kind">
          <select name="" id="">
            <option value="">ETH</option>
            <option value="">BSC</option>
            <option value="">HECO</option>
          </select>
        </div>
      </template>
    </Head> -->
    <div class="box">
      <div class="record" v-for="(i, id) of recordList" :key="id">
        <p>地址：{{i.address}}</p>
        <p>社区算力：{{i.personSl.toFixed(8)}}</p>
        <p>社区空投收益：{{i.personDayIncome.toFixed(8)}}</p>
        <p>时间：{{i.createTime}}</p>
      </div>
    </div>
    
  </div>
</template>

<script>
import { encodeAddressToBech32 } from "../util/encodeAddressToBech32";
import qs from 'qs'
import HomeHead from "../components/homehead.vue";
import Tabs from "../components/tabs.vue"
import Head from '../components/head.vue'
export default {
  name: "Airdrop",
  components: {
    Tabs,
    //Head,
    HomeHead
  },
  data() {
    return {
      recordList: [],
      queryInfo: {
        address: '',
        pageNum: 1,
        pageSize: 999
      }
    };
  },
  created() {
    this.bus.$on('detail', res => {
      console.log('detail:', res);
      this.queryInfo.address = res
    })

  },
  mounted() {
    // localStorage.setItem("address", "Smith");
    
    console.log('addr:', this.queryInfo.address);
    this.getData()
  },
  methods: {
    getData() {
      this.$axios
        .post(
          "/lpSlkt/page/list",
          qs.stringify(this.queryInfo)
          //this.queryInfo
          // {
          //   address: this.address,
          //   pageNum: this.pageNum,
          //   pageSize: this.pageSize
          // },
          // {
          //   "content-type": "application/x-www-form-urlencoded"
          // }
          )
        .then(res => {
          console.log(res)
          if(res.status !== 200) {
            console.log('获取失败');
          }
          res.data.data.content.forEach(item => {
            item.address = encodeAddressToBech32(item.address)
            
          });
          this.recordList= res.data.data.content
        });
    }
  },
};
</script>

<style lang="sass" scoped>
.box 
  // margin: 1.1rem 0
  margin: .1rem 0
.record 
  padding: .2rem 0
  margin: .1rem
  border-radius: .1rem
  color: #fff
  background: #fff
  p 
    padding: .08rem .2rem 
    color:#777  
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
</style>