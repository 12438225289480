<template>
  <div>
    <div class="top">
      <div class="top-left">
        <img class="i1" src="../assets/images/top/18.png" alt="" />
        <img class="i2" src="../assets/images/top/malasong.png" alt="" />
      </div>

      <div class="top-right"> 
		<img class="i3" src="https://filechain.oss-cn-beijing.aliyuncs.com/faxswapimg/pic001.png" alt="" />
	  </div>

      <!-- <div class="top-left">
				<div class="text1">
					<span>玩转</span>
					<span>DeFi</span>
				</div>
				<div class="text2">
					<span>启动</span>
					<span>FaxSwap</span>
				</div>
				<div class="text3">
					<span>FAX</span>
					<span>流动性挖矿</span>
				</div>
				<div class="text4">
					<span>开启寻金之旅</span>
				</div>
				
			</div>
			<div class="text5">
				<img src="../assets/images/pool/icon-3.png" >
				<img src="../assets/images/pool/icon-4.png" >
				<img src="../assets/images/pool/icon-4.png" >
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
};
</script>

<style scoped>
/* @import '../assets/css/pool.css'; */
.top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0;
  background-image: url('https://filechain.oss-cn-beijing.aliyuncs.com/faxswapimg/pic002.png');
  background-color: #1f0f49;
  background-size: 100% ;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: 0px -36px;
}
.top-left {
  padding: 25px 20px;
}
.i1 {
  padding-bottom: 10px;
  display: block;
  width: 60px;
  height: 16px;
}
.i2 {
  display: block;
  width: 140px;
  height: 80px;
}
.top-right {
  margin-top: 20px;
  width: 190px;
  height: 220px;
}
.i3 {
  width: 100%;
}

</style>
