<template>
  <div class="mainbody">
    <HomeHead></HomeHead>
		<Top></Top>
    <div class="content">
      <!-- <div class="swiper">
        <div class="text">
          <p class="p1">玩转 <span>DeFi</span></p>
          <p class="p2">启动 <span>FaxSwap</span></p>
          <p class="p3"><span>FAX</span> 漏洞赏金计划</p>
          <p class="p4">开启寻金之旅</p>
          <div>
            <span class="span1"></span>
            <span class="span2"></span>
            <span class="span2"></span>
          </div>
          
        </div>
        <img class="img1" src="../assets/images/home/1.png" alt="" />
      </div> -->

      <div class="pledge">
        <div class="box1">
          <img src="../assets/images/home/3.png" alt="" />
          <p><span>USDT </span> 总流动性质押</p>
        </div>
        <p class="num">{{total}}</p>
        <!-- <p class="num">-</p> -->
        <div class="pool">
          <div class="szc">
            <img src="../assets/images/home/szc1.png" alt=""><span> 双子池</span>
            <p><span>$</span>{{szpool}}</p>
          </div>
          <div class="xjc">
            <img src="../assets/images/home/xjc1.png" alt=""><span> 星际池</span>
            <p><span>$</span>{{xjpool}}</p>
          </div>
        </div>
        <div>
          <div class="datashow" style="background-color: #FFFFFF;">

            <div class="databox">
              <div class="left">
                <p>FAX当前价格</p>
                <h2><span>$</span>{{faxprice}}</h2>
              </div>
              <div class="right">
                <p>FAX余额</p>
                <h2><span></span>{{faxbalance}}</h2>
              </div>
            </div>

            <div class="databox">
              <div class="left">
                <p>FS当前价格</p>
                <h2><span>$</span>{{fsprice}}</h2>
              </div>
              <div class="right">
                <p>FS余额</p>
                <h2><span></span>{{fsbalance}}</h2>
              </div>
            </div>

            <!-- <div class="databox">
              <div class="left">
                <p>手续费分红累计</p>
                <h2><span>$</span>{{fhtotal}}</h2>
              </div>
              <div class="right">
                <p>算力空投累计</p>
                <h2><span></span>{{sltotal}}</h2>
              </div>
            </div> -->

          </div>
        </div>
      </div>

      <!-- <div class="countdown">
          <img src="../assets/images/home/2.png" alt="">
          <div class="txt">
            <p>Starlight List</p>
            <p>Reward</p>
            <p>Countdown</p>
          </div>
          <div class="showtime">
            <p>星耀榜奖励倒计时</p>
            <div>
              <div>{{days}}</div><span> : </span>
              <div>{{hours}}</div><span> : </span>
              <div>{{minutes}}</div><span> : </span>
              <div>{{seconds}}</div>
            </div>
          </div>
      </div> -->

      <div class="mobility">
        <div class="head">
          <div>
            <img src="../assets/images/home/mobility1.png" alt=""><span>流动性挖矿</span>
          </div>
          <p>More</p>
        </div>
        <div class="mbox">
          <div class="mhead">
            <p>交易对</p>
            <p>产出币种</p>
            <p>单利年化</p>
          </div>

          <!-- <div class="items">
            <div>
              <img class="mobilityimg" style="width: .46rem" src="../assets/images/home/icon1.png" alt="">
              <p>FAX/WUSDT</p>
            </div>
            <p class="mobilitytxt">FAX/FS</p>
            <p>-</p>
          </div> -->

          <div class="items" v-for="(i, id) of mlist.slice(0)" :key="id">
            <div>
              <img :src=i.picture>
              <p>{{i.exchange}}</p>
            </div>
            <p>{{i.coin}}</p>
            <p>{{(i.rate*100).toFixed(2)}}%</p>
          </div>

        </div>
        
      </div>

      <div class="ceo">
        <div class="head">
          <div>
            <img style="width:.45rem;height:.45rem" src="../assets/images/home/ceo1.png" alt=""><span>基金会</span>
          </div>
          <p>More</p>
        </div>
        <div class="mbox">
          <div class="mhead">
            <p>质押币种</p>
            <p>产出币种</p>
            <p>复利年化</p>
          </div>

          <div class="items">
            <div>
              <img class="ceoimg" style="width: .46rem" src="../assets/images/home/icon1.png" alt="">
              <p>FAX</p>
            </div>
            <p>FAX</p>
            <p>-</p>
          </div>

          <div class="items" v-for="(i, id) of ceolist.slice(1) " :key="id">
            <div>
              <img src="../assets/images/home/icon2.png" alt="">
              <p>{{i.zyCoin}}</p>
            </div>
            <p>{{i.proCoin}}</p>
            <p>{{i.rate}}</p>
          </div>

        </div>
      </div>

      <!-- <div class="lp">
        <div class="head">
          <div>
            <img style="width:.43rem;height:.43rem" src="../assets/images/home/lp1.png" alt=""><span>LP算力星耀榜</span>
          </div>
          <p>More</p>
        </div>
        <div class="mbox">
          <div class="mhead">
            <p>排名</p>
            <p>钱包地址</p>
            <p>奖励 FAX</p>
          </div>

          <div class="items" v-for="(i, id) of lplist " :key="id">
            <div>
              <p>{{lplist.indexOf(i) + 1}}</p>
            </div>
            <p class="addr">{{(i.address)}}</p>
            <p class="jl">{{i.jlFax}}</p>
          </div>

       
        </div>
      </div> -->

      <div class="fs">
        <p class="fstitle">FS应用场景</p>
        <!-- <p>FS Application Scenario</p> -->
        <div class="application">
          <div>
            <img src="../assets/images/home/zhang.png" alt="">
            <h2>回购持涨</h2>
            <p>用于手续费回购</p>
          </div>

           <div>
            <img style="width:.5rem" src="../assets/images/home/ceo.png" alt="">
            <h2>基金会分红</h2>
            <p>质押FS分红奖励</p>
          </div>

           <div>
            <img src="../assets/images/home/dao.png" alt="">
            <h2>Dao</h2>
            <p>社区提议及投票</p>
          </div>

        </div>
      </div>

    </div>
    <Tabs :select="1"></Tabs>
  </div>
</template>

<script>
let Web3 = require("web3");
import { encodeAddressToBech32 } from "../util/encodeAddressToBech32";
import Top from "../components/top.vue";
import Tabs from "../components/tabs.vue";
//import Head from "../components/head.vue";
import HomeHead from "../components/homehead.vue";
export default {
  name: "Home",
  components: {
    Tabs,
    //Head,
    HomeHead,
    Top
  },
  data() {
    return {
      walletAddress: '',
      total: '',
      szpool: '',
      xjpool: '',
      faxprice: '',
      faxbalance: '',
      fsprice: '',
      fsbalance: '',
      fhtotal: '',
      sltotal: '',
      mlist: [],
      ceolist: [],
      lplist: [],
      days: '',
      hours: '',
      minutes: '',
      seconds: ''
      
    }
  },
  created() {
    //
    this.getpledge()
    this.getmobility()
    this.getceo()
    this.getlp()
    this.countdown()

    this.taketime()
    
    //this.getdata()
  },
  mounted() {
    let timer =setInterval(() => {
        if(window && window.ethereum) {
          console.log('window.ethereum',window.ethereum)
          this.getdata()
          clearInterval(timer)
        }
      }, 1000)
  },
  destroyed() {
    let that = this
    clearInterval(that.tim)
    console.log('stop!');
  },
  methods: {
    //间隔调取计时器
    taketime() {
      let that = this
      let tim = window.setInterval(function() {
        that.countdown()
      },1000)

    },
    //计时器
    countdown() {
      var today=new Date(),//当前时间
        h=today.getHours(),
        m=today.getMinutes(),
        s=today.getSeconds();
      // var stopTime=new Date("2021/09/07 16:31:00"),//结束时间
      var stopTime=new Date("2021/11/01 00:00:00"),//结束时间
        stopH=stopTime.getHours(),
        stopM=stopTime.getMinutes(),
        stopS=stopTime.getSeconds();
      var shenyu=stopTime.getTime()-today.getTime(),//倒计时毫秒数
        shengyuD=(parseInt(shenyu/(60*60*24*1000))).toString().padStart(2,'0'),//转换为天
        D=parseInt(shenyu)-parseInt(shengyuD*60*60*24*1000),//除去天的毫秒数

        shengyuH=(parseInt(D/(60*60*1000))).toString().padStart(2,'0'),//除去天的毫秒数转换成小时
        H=D-shengyuH*60*60*1000,//除去天、小时的毫秒数

        shengyuM=(parseInt(H/(60*1000))).toString().padStart(2,'0'),//除去天的毫秒数转换成分钟
        M=H-shengyuM*60*1000;//除去天、小时、分的毫秒数

        var shengyuS=
        (parseInt((shenyu-shengyuD*60*60*24*1000-shengyuH*60*60*1000-shengyuM*60*1000)/1000))
        .toString().padStart(2,'0')//除去天、小时、分的毫秒数转化为秒
        // document.getElementById("div").innerHTML=(shengyuD+"天"+shengyuH+"小时"+shengyuM+"分"+S+"秒"+"<br>");
        // setTimeout("clock()",500);
        // setTimeout(clock,500);
        
        // console.log('shengyuD:',shengyuD);
        if(shengyuS <= 0) {
          this.days = '00'
          this.hours = '00'
          this.minutes = '00'
          this.seconds = '00'
          return
        }
        this.days = shengyuD
        this.hours = shengyuH
        this.minutes = shengyuM
        this.seconds = shengyuS

    },
    getpledge() {
      this.$axios.post('/swapMain/ldxzy ')
      .then(res => {
        console.log("pledge" ,res);
        if(res.status !== 200){
          console.log('获取失败!')
          }
        this.total = res.data.data.total > 0 ? res.data.data.total.toFixed(8) : '-'
        this.szpool = res.data.data.szPool > 0 ? res.data.data.szPool.toFixed(4) : '-'
        this.xjpool = res.data.data.xjPool > 0 ? res.data.data.xjPool.toFixed(4) : '-'
        this.faxprice = res.data.data.faxPrice > 0 ? res.data.data.faxPrice.toFixed(4) : '-'
        //this.faxbalance = res.data.data.faxBalance>0?res.data.data.faxBalance:'-'
        this.fsprice = res.data.data.fsPrice > 0 ? res.data.data.fsPrice.toFixed(4) : '-'
        // this.fsbalance = res.data.data.fsBalance>0?res.data.data.fsBalance:'-'
        this.fhtotal = res.data.data.sxfFhTotal > 0 ? res.data.data.sxfFhTotal.toFixed(4) : '-'
        this.sltotal = res.data.data.slktTotal > 0 ? res.data.data.slktTotal.toFixed(4) : '-'

      })
    },
    getmobility() {
      this.$axios.post('/swapMain/ldwk')
      .then(res => {
        console.log('mobility', res)
        if(res.status !== 200) {
          console.log('获取失败')
        }
        this.mlist = res.data.data
      })
    },
    getceo() {
      this.$axios.post('/swapMain/dsh')
      .then(res => {
        console.log('ceo', res)
        if(res.status !== 200) {
          console.log('获取失败');
        }
        this.ceolist = res.data.data
      })
    },
    getlp() {
      this.$axios.post('/swapMain/lpxyb')
      .then(res => {
        console.log('lp', res);
        if(res.data.status !== 200) {
          console.log('获取失败');
        }
        let finalAddr = res.data.data.content
        finalAddr.forEach(addr => {
          // addr.address = encodeAddressToBech32(addr.address)
          // let len= addr.address.length-12
          // let xing = ''
          // for(let i=0; i<4; i++) {
          //   xing += '*'
          // }
          addr.address = addr.address.substring(0,6)+ '****' + addr.address.substring(addr.address.length-6)
          
        });
        console.log('finalAddr:', finalAddr);
        this.lplist = finalAddr
      })
    },
    async getdata() {
      
      //console.log("加载 1 " + this.walletAddress);
      //if (window && window.ethereum) {
        let web3 = new Web3(window.ethereum);
        let addres = await window.ethereum.enable();
        //console.log('addres:',addres);
        //this.walletAddressF1 = encodeAddressToBech32(addres[0]);
        this.walletAddress = addres[0];
        console.log("努力的加载 2 " + this.walletAddress);
        //this.invitConfirm(); //邀请确认
        //this.getIncomeVal(); //获取收益值
      //}
      // this.walletAddress='0x7e6Fe1665a2dDaCF8C0a4545b3858622C4b285D8'
      web3 = new Web3(
        new Web3.providers.HttpProvider("http://api.faxchain.org")
      );
      //获取fax余额
      web3.eth.getBalance(this.walletAddress).then((res) => {
        console.log("fax:", res);
        this.faxbalance = res / Math.pow(10, 18) > 0 ? (res / Math.pow(10, 18)).toFixed(8) : '-'
      });
      //获取fs余额
      var encodeData = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        [this.walletAddress]
      );

      console.log("encodeData:", encodeData);

      web3.eth
        .call({
          from: this.walletAddress,
          to: "0xa178Cf8521b04dd10cec5bb1c2559e0D3D95A0e2",
          data: encodeData,
        })
        .then((res) => {
          console.log("fs:", res);
          //console.log('balance:', JSON.stringify(res));
          //console.log('balance-util:', JSON.stringify(web3.utils.fromWei(web3.eth.abi.decodeParameter("uint256",res),"ether")));
          this.fsbalance = res / Math.pow(10, 18) > 0 ? (res / Math.pow(10, 18)).toFixed(8) : '-'
        });
    },
  }
};
</script>

<style lang="sass" scoped>
.mainbody
  padding-bottom: .8rem
.content
  padding: 12px
  position: relative
  top: -70px
  .swiper
    padding-top: .8rem
    display: flex
    justify-content: center
    //width: 100%
    height: 5.5rem
    box-sizing: border-box
    background: #5F77F9
    p
      margin: .15rem 0
    .text
      color: #fff
      .p1
        font-size: .4rem
        font-weight: lighter
        font-family: MicrosoftYaHeiLight
        span
          font-size: .55rem
          color: #FEC75C
          font-weight: 400
          font-family: Poppins-Medium
      .p2
        font-size: .4rem
        font-weight: Regular
        font-family: MicrosoftYaHei
        span
          font-size: .6rem
          font-weight: Bold
          font-family: Poppins-Bold
      .p3
        span
          font-weight: Bold
          font-family: MicrosoftYaHeiBold
      .p4
        margin-top: 0.3rem
        padding: .12rem .1rem
        width: 2.2rem
        border-radius: .3rem
        font-size: .28rem
        text-align: center
        background: #F94A5E
      >div
        position: absolute
        left: 45%
        top: 39%
        width: 1rem   
        height: 0.1rem
        display: flex
        justify-content: space-around
        .span1
          display: block
          width: .55rem
          height: 0.09rem
          background: #B9C1EA
          border-radius: .2rem
        .span2
          display: block
          width: .09rem
          height: 0.09rem
          background: #8592DA
          border-radius: .2rem
    .img1
      width: 3.3rem
      height: 3.1rem
  .pledge
    //position: relative
    //top: -80px
    padding: .3rem
    margin: 0rem auto 
    //width: 85%
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #F94A5E
    .box1
      padding: .1rem 0
      display: flex
      justify-content: center
      align-items: center
      img
        margin: 0 .1rem
        width: 0.4rem
        height: 0.4rem
      p
        color: #fff
        font-size: .3rem
        font-weight: lighter
        span
          font-size: .3rem
          font-weight: bold
          font-family: Roboto-Bold
    .num
      padding-bottom: 0.1rem
      height: .5rem
      line-height: 0.5rem
      text-align: center
      font-size: .4rem
      color: #fff
      font-weight: bold
      font-family: '黑体'!important
    .pool
      margin: .2rem 0
      display: flex
      justify-content: space-between
      .szc
        padding: .3rem 0 0
        width: 49%
        height: 1.2rem
        text-align: center
        color: #F96271
        border-radius: .1rem
        background: #fff
        img
          margin-top: -0.1rem
          margin-right: 0.12rem
          width: 0.2rem
          height: 0.25rem
        span
          font-size: .3rem
        p  
          padding: .2rem 0 
          font-weight: 700
          font-size: .3rem
          color: #626E88
          span
            color: #626E88
            font-size: .2rem
      .xjc
        padding: .3rem 0 0
        width: 49%
        height: 1.2rem
        text-align: center
        color: #F96271
        border-radius: .1rem
        background: #fff
        img
          margin-right: 0.1rem
          margin-top: -0.1rem
          width: 0.3rem
          height: 0.3rem
        span
          font-size: .3rem
        p  
          padding: .2rem 0
          font-weight: 700
          font-size: .3rem
          color: #626E88
          span
            color: #626E88
            font-size: .2rem
    .datashow
      padding: .1rem
      border-radius: .1rem
      background: #ffff
      .databox
        padding: 0rem .2rem
        display: flex
        border-bottom: 1px solid #ECECEC
        &:last-child
          border-bottom: none
        .left, .right
          width: 50%
          p  
            // margin: .16rem 0
            height: .5rem   
            line-height: .5rem
            font-size: .28rem
            color: #8B98BC
            font-family: SourceHanSansCN-ExtraLight
          h2
            height: .5rem
            line-height: .4rem
            font-weight: 700
            font-size: .28rem
            // padding-bottom: 0.3rem
            font-weight: bold
            font-family: Roboto-Bold
            color:#626E88
            text-overflow:ellipsis
            white-space: nowrap
            overflow: hidden
            span
              font-size: .26rem
  .countdown
    margin: .3rem auto
    //width: 92%
    display: flex
    justify-content: space-between
    align-items: center
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #5F77F9
    img
      padding: .1rem 0
      width: 1.8rem
      height: 1.6rem
    .txt
      margin-right: .1rem
      font-weight: bold
      font-family: Roboto-Bold
      color: #fff
      font-size: .33rem
    .showtime
      margin-right: .3rem
      display: flex
      flex-direction: column
      align-items: center
      p  
        padding: .15rem 0
        color: #fff
        font-size: .3rem
        font-weight: lighter
        font-family: SourceHanSansCN-Light
      >div
        padding: .1rem 0
        display: flex
        align-items: center
        div
          display: flex
          justify-content: center
          align-items: center
          padding: .1rem
          width: 0.3rem
          height: 0.3rem
          color: #5F77F9
          font-size: .28rem
          border-radius: .1rem
          background: #fff
        span
          padding: 0 .02rem
          color: #fff
  .mobility, .ceo, .lp
    margin: .3rem auto
    //width: 93%
    overflow: hidden
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #fff
    .head
      padding: .3rem .3rem
      display: flex
      justify-content: space-between
      align-items: center
      background: #F94A5E
      div
        display: flex
        align-items: center
        img
          width: 0.45rem
          height: 0.45rem
        span
          margin: 0 .2rem
          font-size: .33rem   
          color: #fff
      p 
        padding: .05rem .22rem   
        border: 1px solid #fff
        border-radius: .3rem
        color: #fff
        font-size: .26rem
    .mbox
      padding: 0 .1rem
      .mhead
        display: flex
        // justify-content: space-between
        padding: .3rem .4rem 
        font-size: .28rem
        color: #000
        //background: #F94A5E
        font-family: SourceHanSansCN-Normal
        >p   
          flex: 1
      .items
        height: .3rem
        padding: .3rem 
        display: flex
        // justify-content: space-between
        align-items: center
        font-size: .3rem
        color: #777777
        div
          flex: 19%
          display: flex
          align-items: center
          font-family: Roboto-Light
          img
            margin-right: 0.1rem
            width: 0.4rem
            height: 0.4rem
        p 
          flex: 1
          //width: 40%
  .mobility
    .mbox
      .mhead
        p
          &:nth-child(1)
            // margin-left: .9rem
            flex: 10%
      .items
        div
          flex: 10%
          .mobilityimg
            // margin-left: -0.04rem
        .mobilitytxt
          // margin-left: -0.6rem

  .ceo
    margin: .3rem auto
    //width: 100%
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #fff
    .head 
      div
        img
          width: 0.6rem
          height: 0.65rem
    .mbox
      .mhead
        p  
          &:first-child
            flex: 10%
      .items
        div
          flex:10%
          .ceoimg
            margin-left: -0.04rem

  .lp
    margin: .3rem auto
    //width: 100%
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #fff
    .head
      div
        img
          width: 0.55rem
          height: 0.55rem
    .mbox
      .mhead
        display: flex
        justify-content: space-between
        p  
          &:last-child
            text-align: right
          &:nth-child(2)
            text-align: center
      .items
        display: flex
        justify-content: space-between
        text-align: center
        >div
          flex: 10%
        .addr
          margin: 0 5px
          flex:80%
          text-align: center
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
        .jl   
         flex: 10%
  .fs   
    margin: .3rem auto
    //width: 93%
    border-radius: .2rem
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    background: #fff
    
    .fstitle
      padding: .4rem
      font-size: .3rem
      color: #777777
      text-align: center
    .application
      display: flex
      align-items: center
      justify-content: space-around
      padding: .1rem
      color: #858585
      div
        text-align: center
        border-top: 1px solid #EFEFEF
        img
          margin-top: .5rem
          width: 0.5rem
          height: 0.5rem
        h2  
          margin-top: 0.5rem
          font-size: .3rem
        p  
          padding: .15rem 0
          font-size: .2rem
</style>