<template>
	<div id="">
		<HomeHead></HomeHead>
		<div class="padding" style="padding-bottom: 100px;">
			<div class="head bold">
				FaxSwap教程集锦
			</div>
			<hr />
			<div >
				<h2 class="bold bold1">一.钱包问题</h2>
				<h3 class="sh">FaxWallet钱包目前只支持安卓版本，网页版钱包可以使用METAMASK钱包，苹果版本可以使用TP、IMTOKEN、火币等其他钱包。</h3>
				<h3 class="sh">FaxWallet下载链接：<a href="http://faxwallet.org">http://faxwallet.org</a></h3>
				<h3 class="sh">Metamask官网链接：<a href="https://metamask.io">https://metamask.io</a></h3>
				<h3 class="sh">TokenPocket下载链接：<a href="https://tokenpocket.pro">https://tokenpocket.pro</a></h3>
				<h3 class="sh">Imtoken下载链接：<a href="https://token.im">https://token.im</a></h3>
				<h3 class="sh">Huobi下载链接：<a href="https://huobiwallet.com">https://huobiwallet.com</a></h3>
				<h3 class="sh">网络名称：FaxChain</h3>
				<h3 class="sh">节点连接RPC：<a href="http://api.faxchain.org">http://api.faxchain.org</a></h3>
				<h3 class="sh">链ID：778777</h3>
				<h3 class="sh">默认代币符号：FAX</h3>
				<!-- <h3 class="bold small-margin-top">2. FaxSwap挖矿教程</h3>
				<h3 class="">https://www.（公告栏内网址）</h3> -->
				<h2 class="bold margin-top bold1">二.APY、奖励计算方式</h2>
				<h2 class="bold">1.双子池挖矿</h2>
				<h3 class="sh">添加FAX/WUSDT 的流动性并质押LP挖矿，同时产出FAX和FS，为期730天。</h3>
				<!-- <h2 class="bold small-margin-top">2.星际池挖矿</h2>
				<h3 class="sh">添加FS/FAX的流动性并质押LP挖矿，产出FaxSwap的治理通证FS，为期3650天。</h3> -->
				<h2 class="bold small-margin-top">2.流动性挖矿APY</h2>
				<h3 class="sh">（流动性质押量/总质押量）*每日产量*当前币价*365*100%</h3>
				<h2 class="bold small-margin-top">3.FAX每日产量</h2>
				<h3 class="shsh">
					每日矿池产量 >=
					<img style="width:10em;height:.25rem" src="../assets/images/text/label5.png" alt="">
				</h3>
				<h3 class="sh">第一天初始产量为9727FAX，之后每天在前一天的基础上增加千分之一，n为第几天。</h3>
				<!-- <h2 class="bold small-margin-top">5.社区算力计算公式:
				<img src="../assets/images/text/label1.png" /></h2>
				
				<h3 class="">MAX(AMT）为最大社区，AMTj为其它所有社区。</h3>
				<h2 class="bold small-margin-top">6.社区算力空投规则</h2>
				<h3 class="">（用户社区算力/所有社区算力之和）* 每日FAX空投量。</h3> -->
				<h2 class="bold small-margin-top">4.FaxSwap手续费分配</h2>
				<h3 class="sh">（FaxSwap交易额0.3%的手续费将全部用于FS的统一回购，进入基金会作为质押FS的分红收益。</h3>
				<h2 class="bold small-margin-top">5.基金会奖励池</h2>
				<h3 class="sh">用户能获得的奖励代币数:用户质押FS量/TVL * 当前奖励池FS数量。</h3>
				<!-- <h2 class="bold small-margin-top">9.LP星耀榜奖贡献值计算规则</h2>
				<h3 class="sh">LP贡献值= <img src="../assets/images/text/label2.png" > * 每周LP增减量</h3>
				<h3 class="sh">贡献值为正数代表算力的增加，为负数代表算力的减少，按照贡献值高低排名。</h3>
				<h2 class="bold small-margin-top">10.LP星耀榜奖励规则</h2>
				<h3 class="sh">
					LP星耀榜每周发放奖励给前20名用户，奖励按照比例分别为第1名-25%，第2名-15%，第3名-10%，第4名-8%，第5名-6%，第6名-5%，第7名-4%，第8名-3%，第9~20名-2%，每周奖励FAX数额随机发放。
				</h3> -->
			</div>
		</div>
	</div>
</template>

<script>
	import HomeHead from "../components/homehead.vue";
	export default {
		name:'FaxSwapCourse',
		components: {
		  HomeHead
		}
	}
</script>

<style>
	.head {
		text-align: center;
		font-size: 20px;
		padding: 30px;
	}

	.bold {
		font-weight: normal;
	}
	
	.bold1 {
		font-weight: bold;
	}
	
	.padding {
		padding: 20px;
	}

	h2{
		line-height: 3em;
	}
	h3{
		line-height: 2em;
	}
	 h2 img,h3 img{
		height: 4em;
		width: auto;
	}
	.margin-top{
		margin-top: 2em;
	}
	.small-margin-top{
		margin-top: 2em;
	}
</style>
