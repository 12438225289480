<template>
  <div style="padding-bottom: 3.6rem">
    <HomeHead></HomeHead>
    <div class="opening">即将开启......</div>
    
    <Tabs :select="4"></Tabs>
  </div>
</template>

<script>

import HomeHead from "../components/homehead.vue";
import Tabs from "../components/tabs.vue"
export default {
  name: "Fundation",
  components: {
    Tabs,
    //Head,
    HomeHead
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="sass" scoped>

.opening
  position: absolute
  top: 40%
  left: 50%
  transform: translate(-50%, 0)
  font-size: 18px   
  color: #777 

</style>