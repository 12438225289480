<template>
  <div id="">
    <HomeHead> </HomeHead>

    <div class="padding" style="padding-bottom: 100px">
      <div class="head bold">
        <p>关于社区算力空投的开启公告</p>
      </div>
      <hr />
      <div>
        <h2 class="bold margin-top">尊敬的用户：</h2>
        <h2>
          经过严谨的数据校对，FaxSwap已于2021年08月20日 24:00（GMT+8）开启社区算力空投。
        </h2>

        <h2 class="bold margin-top">具体算力空投规则如下：</h2>

        <h2>
          1. 每日社区算力的FAX空投数量和Gemini双子池相同,每日矿池产量=每日算力空投量= <img class="label1" src="../assets/images/text/label4.png" >
        </h2>
        <h2>2. 获取社区算力的基本要求是当前账户已参与LP质押挖矿，数量不限。</h2>
        <h2>
          3. 每个账户的社区算力，是按照算法计算得出算力数值，社区算力计算公式如下：
          <img class="label2" src="../assets/images/text/label1.png" >
          MAX(AMT）为最大社区，AMTj为其它所有社区 
        </h2>
        <h2>
          4. 每个账户每日空头量按照算力占比获得， 社区算力空投计算公式：
          社区算力/所有社区算力之和*每日FAX空投量 。
        </h2>
        <h2>
          5. 获得的算力空投FAX可以随意提取，没有任何限制。
        </h2>
        <h2>
          6. 所有官方动态将显示在FaxSwap的公告栏内，敬请关注官网公告、教程锦集和FAQ。
        </h2>
        <h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
        <h2 class="bold">2021 年 8 月 20 日&emsp;</h2>
        <h2>&emsp;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
  name: "Notice2",
  components: {
    HomeHead,
  },
};
</script>

<style>
.label1 {
  margin-bottom: 4px;
  width: 90px;
  height: 12px;
}
.label2 {
  margin-left: -20px;
  width: 360px;
  height: 50px;
}
.head {
  text-align: center;
  font-size: 20px;
  padding: 30px 0;
}
p {
  padding: 5px 0;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 30px;
}
.margin-top {
  margin-top: 2em;
}
h2 {
  line-height: 3em;
}
h3 {
  line-height: 2em;
}
</style>