<template>
  <div class="head">
    <slot name="back">
      <img
        @click="drawer = true"
        class="i1"
        src="../assets/images/head/sidebar.png"
        alt=""
      />
    </slot>

    <el-drawer
      size="50%"
      direction="ltr"
      :visible.sync="drawer"
      :with-header="false"
    >
      <p style="font-size: 0.3rem;"><img src="../assets/images/sidebar/1.png" alt="" /> 公告</p>
      <p style="font-size: 0.3rem;" @click="topage('/FaxSwapCourse')">
        <img style="margin-left:-.05rem" src="../assets/images/sidebar/2.png" alt="" />教程集锦
      </p>
      <p style="font-size: 0.3rem;"><img src="../assets/images/sidebar/3.png" alt="" />跨链桥</p>
      <p style="font-size: 0.3rem;" @click="topage('/FAQ')">
        <img src="../assets/images/sidebar/4.png" alt="" />FAQ
      </p>
      <p style="font-size: 0.3rem;"><img src="../assets/images/sidebar/5.png" alt="" />上币申请</p>
    </el-drawer>

    <!-- <img class="i2" src="../assets/images/head/logo.png" alt="" /> -->
    <!-- <div id="" style="color: #FFFFFF;">
				FaxSwap
			</div> -->
    <!-- <img class="i2" src="../assets/images/head/logo.png" alt="" /> -->

    <!-- <img class="i2" src="../assets/images/head/logo.png" alt="" /> -->
    <div class="logo"><img src="../assets/images/home/icon2.png" alt="" /></div>
    <div class="text">Faxswap</div>
    <img class="earth" src="../assets/images/head/earth1.png" alt="">

    <!-- <slot name="connect">
      <div class="connect">Connect to a wallet</div>
      
    </slot> -->
    <!-- <div class="connect">Connect to a wallet</div> -->
    <!-- <img class="i3" src="../assets/images/head/set.png" alt="" /> -->
  </div>
</template>

<script>
export default {
  name: "Head",
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    topage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style lang="sass" scoped>
.logo
  margin-left: .8rem
  width: 0.5rem
  height: 0.5rem
  border-radius: .25rem
  background: #fff
  img
    width: 100%
.text
  margin-left: .1rem
  font-size: .4rem
  font-weight: 700
  color: #fff
  text-align: center
.earth 
    margin-left: 2.5rem
    width: 0.5rem
    height: 0.5rem
.back
  a
    img
      width: 70%

  
.head
  // position: fixed
  // top:0
  // left:0
  box-sizing: border-box
  display: flex
  //justify-content: space-between
  align-items: center
  padding: .2rem .3rem
  width: 100%
  height: 1rem
  background: #000D49
  border-bottom: 1px solid #F6F7FB
  .el-drawer
    p
      padding: .9rem .5rem 0
      img
        margin-right: 0.1rem
        width: 0.5rem
        height: 0.5rem
  .i1
    width: .5rem
    height: .5rem
  .i2
    width: 2.3rem
    height: 0.6rem
  .connect
    width: 2.8rem
    height: 0.6rem
    text-align: center
    line-height: 0.6rem
    color: #fff
    border-radius: .1rem
    background: #6DE0CB
  .i3
    padding: .1rem
    width: 0.4rem
    height: 0.4rem
    border-radius: .1rem
    background: #fff
</style>