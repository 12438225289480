<template>
  <div id="">
    <HomeHead> </HomeHead>

    <div class="padding" style="padding-bottom: 100px">
      <div class="head bold">
        <p>关于“FAX马拉松”矿工竞赛的开启公告</p>
      </div>
      <hr />
      <div>
        <h2 class="bold margin-top">尊敬的用户：</h2>
        <h2>
          FaxSwap团队感谢所有矿工和用户的支持与关注，为
          了鼓励对FaxSwap做出贡献的矿工和用户，现正式
          启动“FAX马拉松”矿工竞赛。矿工竞赛设立“金、
          银、铜”三个奖项，奖品既有内涵又价值不菲，欢迎 广大矿工和用户积极参与。
        </h2>

        <h2 class="bold margin-top">具体奖励规则如下：</h2>

        <h2>1. 铜牌矿工：</h2>
        <h2>要求：社区算力达2000LP</h2>
        <h2>奖品：投资大师吉姆-罗杰斯新作《危机时代》</h2>
        <h2>数量：3000册</h2>
        <h2>时间：8月25日 - 10月31日</h2>
        <h2>规则：一，规定时间内达标者，在申报表内提交个人领奖信息</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;二，每周统计获奖名单，并根据申报信息发放奖励</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;三，每个钱包地址只限一份</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;四，活动到期自动终止</h2>

        <h2>2. 银牌矿工：</h2>
        <h2>要求：社区算力达等值5万USDT的LP流动性</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;（社区算力价值=社区算力*LP实时价格）</h2>
        <h2>奖品：全球珍藏版100克Faxcoin纯银币（NFT限量发行）</h2>
        <h2>数量：300枚</h2>
        <h2>时间：第一期 10月1日 - 10月30日 </h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;第二期 11月1日 - 11月30日</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;第三期 12月1日 - 12月30日</h2>
        <h2>规则：一，规定时间内达标者，在申报表内提交个人领奖信息</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;二，每月统计获奖名单，并根据申报信息发放奖励</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;三，每个钱包地址只限一份</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;四，活动到期自动终止</h2>


        <h2>3. 金牌矿工：</h2>
        <h2>要求：社区算力达等值50万USDT的LP流动性</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;（社区算力价值=社区算力*LP实时价格）</h2>
        <h2>奖品：全球珍藏版100克Faxcoin纯金币（NFT限量发行）</h2>
        <h2>数量：30枚</h2>
        <h2>时间：2021年12月-2022年6月 </h2>
        <h2>规则：一，规定时间内达标者，在申报表内提交个人领奖信息</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;二，每月统计获奖名单，并根据申报信息发放奖励</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;三，每个钱包地址只限一份</h2>
        <h2>&emsp;&emsp;&nbsp;&nbsp;四，活动到期自动终止</h2>

        <h2 class="bold margin-top">
            奖励申报表：
            <a href="https://shimo.im/forms/PHyXpXRHQXTPp9CP/fill">https://shimo.im/forms/PHyXpXRHQXTPp9CP/fill</a>
        </h2>


        
        <h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
        <h2 class="bold">2021 年 8 月 24 日&emsp;</h2>
        <h2>&emsp;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
  name: "Notice3",
  components: {
    HomeHead,
  },
};
</script>

<style>
a {
    font-size: 14px;
    color: blue;
    font-weight: 400;
    text-decoration-line: underline;
}
.head {
  text-align: center;
  font-size: 20px;
  padding: 30px 0;
}
p {
  padding: 5px 0;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 30px;
}
.margin-top {
  margin-top: 2em;
}
h2 {
  line-height: 3em;
}
h3 {
  line-height: 2em;
}
</style>