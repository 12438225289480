<template>
  <div class="head">
    <slot name="back">
      <img
        @click="drawer = true"
        class="i1"
        src="../assets/images/head/menu.png"
        alt=""
      />
    </slot>

    <el-drawer
      size="60%"
      direction="ltr"
      :visible.sync="drawer"
      :with-header="false"
    >
      <p></p>
      <p style="font-size: 0.3rem" @click="topage('/Notices')">
        <img src="../assets/images/sidebar/1.png" alt="" /> 公告
      </p>
      <p style="font-size: 0.3rem" @click="topage('/FaxSwapCourse')">
        <img
          style="margin-left: -0.05rem"
          src="../assets/images/sidebar/2.png"
          alt=""
        />教程集锦
      </p>
      <p style="font-size: 0.3rem">
        <img src="../assets/images/sidebar/3.png" alt="" />跨链桥
      </p>
      <p style="font-size: 0.3rem" @click="topage('/FAQ')">
        <img src="../assets/images/sidebar/4.png" alt="" />FAQ
      </p>
      <p style="font-size: 0.3rem" @click="topage('/applyLP')">
        <img src="../assets/images/sidebar/5.png" alt="" />
        上币申请
      </p>
      <!-- <p style="font-size: 0.3rem" @click="topage('/tokenVesting')">
        <img src="../assets/images/sidebar/6.png" alt="" />
        锁仓
      </p> -->
      <!-- <p style="font-size: 0.3rem" @click="topage('/login')">
        <img src="../assets/images/sidebar/4.png" alt="" />LP挖矿审核
      </p> -->
    </el-drawer>
    <img class="logo" src="../assets/images/home/icon2.png" alt="" />
    <div @click="topage('/home')" class="text">FaxSwap</div>
    <img class="earth" src="../assets/images/head/earth.png" alt="" />

    <!-- <img class="i2" src="../assets/images/head/logo.png" alt="" /> -->

    <!-- <slot name="connect">
      <div class="connect">Connect to a wallet</div>
    </slot> -->
    <!-- <div class="connect">Connect to a wallet</div> -->
    <!-- <img class="i3" src="../assets/images/head/set.png" alt="" /> -->
  </div>
</template>

<script>
export default {
  name: "HomeHead",
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    topage(page) {
      this.$router.push(page);
    },
  },
};
</script>

<style lang="sass" scoped>
.back
  a
    img
      width: 70%
.kind
  color: white
  font-weight: 700
  select
    padding: .12rem .2rem
    border-radius: .1rem
.head
  // position: fixed
  // top:0
  // left:0
  box-sizing: border-box
  display: flex
  //justify-content: space-between
  align-items: center
  padding: 15px
  width: 100%
  height: 40px
  background: #fff
  border-bottom: 1px solid #F6F7FB
  .el-drawer
    p
      padding: .4rem 0 .4rem .8rem
      text-align: left
      img
        margin-right: 0.1rem
        width: 26px
        height: 26px
  .logo
    margin-left: 30px
    width: 20px
    height: 20px
  .text
    margin-left: 10px
    font-size: 18px
    font-weight: 700
    text-align: center
  .earth
    margin-left: 40%
    width: 20px
    height: 20px
  .i1
    width: 30px
    height: 30px
  // .i2
  //   width: 2.3rem
  //   height: 0.6rem
//   .connect
//     width: 2.8rem
//     height: 0.6rem
//     text-align: center
//     line-height: 0.6rem
//     color: #fff
//     border-radius: .1rem
//     background: #6DE0CB
//   .i3
//     padding: .1rem
//     width: 0.4rem
//     height: 0.4rem
//     border-radius: .1rem
//     background: #fff
</style>
