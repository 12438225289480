<template>
  <div id="">
    <HomeHead> </HomeHead>

    <div class="padding" style="padding-bottom: 100px">
      <div class="head bold">
        <p>关于FaxSwap短暂数据出错的公告</p>
      </div>
      <hr />
      <div>
        <h2 class="bold margin-top">尊敬的用户：</h2>
        <h2>
          由于DXC上线数据统计出现短暂性显示出错，FaxSwap团队表示歉意，现所有数据已经更新完毕。
          由于数据显示和Swap上有同步的时间差，请务必以Swap流动池的价格为准。
        </h2>
        <h2 class="bold margin-top">FaxSwap 团队&emsp;</h2>
        <h2 class="bold">2021 年 10 月 14 日&emsp;</h2>
        <h2>&emsp;</h2>
      </div>
    </div>
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
  name: "Notice2",
  components: {
    HomeHead,
  },
};
</script>

<style>
.label1 {
  margin-bottom: 4px;
  width: 90px;
  height: 12px;
}
.label2 {
  margin-left: -20px;
  width: 360px;
  height: 50px;
}
.head {
  text-align: center;
  font-size: 20px;
  padding: 30px 0;
}
p {
  padding: 5px 0;
}

.bold {
  font-weight: bold;
}

.padding {
  padding: 30px;
}
.margin-top {
  margin-top: 2em;
}
h2 {
  line-height: 3em;
}
h3 {
  line-height: 2em;
}
</style>