
// '0x....' 转 'f123334...'

//import bech32 from 'bech32'
let bech32 = require('bech32')
import {Buffer} from 'buffer'

export const encodeAddressToBech32 = (hexAddr, prefix = "f") => {

    //console.log('bech32:', bech32);
    hexAddr = hexAddr.slice(0, 2) === '0x' ? hexAddr.slice(2) : hexAddr
    const words = bech32.toWords(Buffer.from(hexAddr, "hex"))
    return bech32.encode(prefix, words)
}