<template>
  <div class="box">
      <div class="header">
			<p @click="back" class="back"> 返回 </p>
	  </div>
    <div id="code">
        
      <div
        style="
          width: 6rem;
          height: 5rem;
          background-color: #fff;
          margin: 0 auto ;
          text-align: center;
        "
      ></div>
      <p class="tip" style="text-align: center">...载入中...</p>
    </div>
    <p>将二维码放入框内，即可自动扫描</p>
    <p>{{result}}</p>
    <!-- <div class="sweep" @click="sweep">开始扫码</div> -->
    <div class="flash" @click="flash">开启闪光灯</div>
  </div>
</template>

<script>
import vueBarcode from 'vue-barcode'
export default {
  name: "Scanqr",
  data() {
    return {
      scan: "",
      results: ''
    };
  },
  created() {
    
  },
  destroyed() {
      this.bus.$emit('scan',this.results)
      const self = this;
      self.scan.cancel();
      self.scan.close();
  },
  mounted() {
    const self = this;
    
    if (window.plus) {
      self.plusReady();
    } else {
      document.addEventListener("plusready", self.plusReady, false);
    }
    document.addEventListener(
      "DOMContentLoaded",
      function () {
        //alert("DOMLoaded");
        self.domready = true;
        self.plusReady();
      },
      false
    );
    self.sweep()
  },
  methods: {
    plusReady() {
      const self = this;
      // 获取窗口
      self.scan = new plus.barcode.Barcode("code");
      self.scan.onmarked = self.onmarked;
      console.log(self.scan);
    },

    sweep() {
      const self = this;
      self.scan.start();
    },
    back() {
      const self = this;
      self.scan.cancel();
      self.scan.close();
      this.$router.go(-1)
    },
    flash() {
      const self = this;
      self.scan.setFlash(true);

    },
    onmarked(type, result) {
      //var text = "未知: ";

      switch (type) {
        case plus.barcode.QR:
          type = "QR";
          break;
        // case plus.barcode.EAN13:
        //   type = "EAN13";
        //   break;
        // case plus.barcode.EAN8:
        //   type = "EAN8";
        //   break;
        // default:
        //   type = "其他" + type;
        //   break;
      }
      //alert(result)

      result = result.replace(/\n/g, "");
      this.results = result
      this.$message.success('扫描完成！')
      this.$router.go(-1)

    //   if (result.indexOf("http://") == 0 || result.indexOf("https://") == 0) {
    //     plus.nativeUI.confirm(
    //       "二维码类型：" + type + "\n扫描结果：" + result,
    //       function (i) {
    //         if (i.index == 0) {
    //           var urlStr = encodeURI(result);
    //           plus.runtime.openURL(urlStr);
    //         } else {
    //           self.back(); //返回上个页面
    //         }
    //       },
    //       "",
    //       ["打开", "取消"]
    //     );
    //   } else {
    //     self.back(); //返回上个页面
    //     plus.nativeUI.alert(
    //       "二维码类型：" + type + "\n扫描结果：" + result + "\n文件路径：" + f
    //     );
    //   }
    },
  },
};
</script>

<style lang="sass" scoped>
.back
  margin: .2rem 
  width: 1.5rem
  font-weight: 700
  background: #ccc
  border-radius: 50px 
p
  padding: .2rem
.box
  width: 100%
  height: 350px
  text-align: center
  #code
    width: 100%
    height: 400px
  // .sweep
  //   padding: 15px
  //   text-align: center
  //   background: #ddd
  //   border-radius: 20px
  .flash
    margin: 20px auto
    padding: 15px
    width: 100px
    text-align: center
    background: #ddd
    border-radius: 30px
</style>