<template>
  <div class="blocks">
    <HomeHead></HomeHead>
    <div class="block">
      <!-- 头部 -->
      <div class="head">LP挖矿申请</div>

      <!-- 表单 -->
      <div class="bodys" style="display: none">
        <div class="body">
          <div>*LP池交易对名称 ( Token Name )</div>
          <input type="text" placeholder="输入交易对名称" />
        </div>
        <div class="body">
          <div>*LP池币种代码 ( Token Symbol )</div>
          <input type="text" placeholder="输入币种代码" />
        </div>
        <div class="body">
          <div>*LP池币种logo链接</div>
          <input type="text" placeholder="输入链接" />
        </div>
        <div class="body">
          <div>*合约地址</div>
          <input type="text" placeholder="输入合约地址" />
        </div>
        <div class="body">
          <div>*项目简介</div>
          <input type="text" placeholder="输入简介" />
        </div>
        <div class="body">
          <div>*币种官方网站</div>
          <input type="text" placeholder="输入网站" />
        </div>
        <div class="body">
          <div>*币种白皮书网站</div>
          <input type="text" placeholder="输入网站" />
        </div>
        <div class="body">
          <div>*项目在Github上的链接地址</div>
          <input type="text" placeholder="输入链接" />
        </div>
        <div class="body">
          <div>*Twitter或Telegram链接</div>
          <input type="text" placeholder="输入链接" />
        </div>
        <div class="body">
          <div>*您的Email</div>
          <input type="text" placeholder="请输入您的Email" />
        </div>
        <div class="body">
          <div>*其他联系方式 (手机或电话)</div>
          <input type="text" placeholder="输入其他联系方式 (手机或电话)" />
        </div>
        <div class="submit">
          <div>*为必填项目</div>
          <button>提交</button>
        </div>
      </div>

      <!-- 表单体 -->
      <div class="bodys">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
          size="mini"
        >
          <el-form-item
            label="LP池交易对名称 ( Token Name )"
            prop="tokenNameOne"
          >
            <el-input
              v-model="ruleForm.tokenNameOne"
              placeholder="输入交易对名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="tokenNameTwo">
            <el-input
              v-model="ruleForm.tokenNameTwo"
              placeholder="输入交易对名称"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="LP池币种代码 ( Token Symbol )"
            prop="tokenSymbolOne"
          >
            <el-input
              v-model="ruleForm.tokenSymbolOne"
              placeholder="输入币种代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="tokenSymbolTwo">
            <el-input
              v-model="ruleForm.tokenSymbolTwo"
              placeholder="输入币种代码"
            ></el-input>
          </el-form-item>

          <el-form-item label="LP池币种logo链接" prop="tokenLogoOne">
            <el-input
              v-model="ruleForm.tokenLogoOne"
              placeholder="输入链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="tokenLogoTwo">
            <el-input
              v-model="ruleForm.tokenLogoTwo"
              placeholder="输入链接"
            ></el-input>
          </el-form-item>

          <el-form-item label="合约地址" prop="contractAddressOne">
            <el-input
              v-model="ruleForm.contractAddressOne"
              placeholder="输入合约地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="" prop="contractAddressTwo">
            <el-input
              v-model="ruleForm.contractAddressTwo"
              placeholder="输入合约地址"
            ></el-input>
          </el-form-item>

          <el-form-item label="项目简介" prop="projectIntroduction">
            <el-input
              type="textarea"
              v-model="ruleForm.projectIntroduction"
              placeholder="输入简介"
            ></el-input>
          </el-form-item>
          <el-form-item label="币种官方网站" prop="website">
            <el-input
              v-model="ruleForm.website"
              placeholder="输入网站"
            ></el-input>
          </el-form-item>
          <el-form-item label="币种白皮书网站" prop="whitePaperWebsite">
            <el-input
              v-model="ruleForm.whitePaperWebsite"
              placeholder="输入网站"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目在Github上的链接地址" prop="githubWebsite">
            <el-input
              v-model="ruleForm.githubWebsite"
              placeholder="输入链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="Twitter或Telegram链接" prop="teleTwitterWebsite">
            <el-input
              v-model="ruleForm.teleTwitterWebsite"
              placeholder="输入链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="您的Email" prop="email">
            <el-input
              v-model="ruleForm.email"
              placeholder="请输入您的Email"
            ></el-input>
          </el-form-item>
          <el-form-item label="其他联系方式 (手机或电话)" prop="contactPhone">
            <el-input
              v-model="ruleForm.contactPhone"
              placeholder="输入其他联系方式 (手机或电话)"
            ></el-input>
          </el-form-item>

          <div class="tips">
            <div>*为必填项目</div>
            <div>
              <!-- <el-button
                @click="$router.push('/myLPApply')"
                type="default"
                size="mini"
                >已申请</el-button
              > -->
              <el-button
                @click="submitForm('ruleForm')"
                type="primary"
                size="mini"
                >提交</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
let Web3 = require("web3");
import HomeHead from "../components/homehead.vue";
export default {
  components: {
    HomeHead,
  },
  data() {
    return {
      ruleForm1: {
        tokenNameOne: "faxchain",
        tokenNameTwo: "faxswap",
        tokenSymbolOne: "FAX",
        tokenSymbolTwo: "FS",
        tokenLogoOne: "https://www.baidu.com",
        tokenLogoTwo: "https://www.baidu.com",
        contractAddressOne: "0x22ddcdwedcedsdfc4445",
        contractAddressTwo: "0x555dendeincededc555",
        projectIntroduction:
          "海滨城市的及时催的完不错那次月份加到你成绩上半年就的成",
        website: "https://www.baidu.com",
        whitePaperWebsite: "https://www.baidu.com",
        githubWebsite: "https://www.baidu.com",
        teleTwitterWebsite: "https://www.baidu.com",
        email: "676438738@qq.com",
        contactPhone: "12345678900",
        applicantContractAddress: "",
      },
      ruleForm: {
        tokenNameOne: "",
        tokenNameTwo: "",
        tokenSymbolOne: "",
        tokenSymbolTwo: "",
        tokenLogoOne: "",
        tokenLogoTwo: "",
        contractAddressOne: "",
        contractAddressTwo: "",
        projectIntroduction: "",
        website: "",
        whitePaperWebsite: "",
        githubWebsite: "",
        teleTwitterWebsite: "",
        email: "",
        contactPhone: "",
        applicantContractAddress: "",
      },
      rules: {
        tokenNameOne: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
        ],
        tokenNameTwo: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
        ],
        tokenSymbolOne: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
        ],
        tokenSymbolTwo: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
        ],
        tokenLogoOne: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        tokenLogoTwo: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        contractAddressOne: [
          {
            required: true,
            message: "请输入正确内容,例如 0x2577ass...",
            trigger: "blur",
          },
        ],
        contractAddressTwo: [
          {
            required: true,
            message: "请输入正确内容,例如 0x2577ass...",
            trigger: "blur",
          },
        ],
        projectIntroduction: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
        ],
        website: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        whitePaperWebsite: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        githubWebsite: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        teleTwitterWebsite: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          {
            type: "url",
            message: "请输入正确的网址,例如 https://www.baidu.com",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入正确内容", trigger: "blur" },
          { min: 11, max: 11, message: "长度为11个字符", trigger: "blur" },
        ],
      },
    };
  },
  async created() {
    let web3 = new Web3(window.ethereum);
    let addres = await window.ethereum.enable();
    this.ruleForm.applicantContractAddress = addres[0];
    console.log(
      "-----applicantContractAddress------",
      this.ruleForm.applicantContractAddress
    );
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(qs.stringify(this.ruleForm))
          this.$axios
            .post("/lpApplication/submit", qs.stringify(this.ruleForm))
            // ({
            //   method: 'post',
            //   url: '/lpApplication/submit',
            //   data: qs.stringify(this.ruleForm),
            //   headers: {'Content-Type': 'text/plain'}
            // })
            .then((res) => {
              console.log("------------res----------", res);
              if (res.data.status == 200) {
                this.$message.success("提交成功!");
                this.$router.push({
                  path: "/myLPApply",
                  query: {
                    iquery: res.data.data.auditStatus,
                  },
                });
                return;
              }
              this.$message.error("申请失败!");
            });
        } else {
          this.$message.error("表单验证失败!");
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.blocks
  .block
    padding: 10px
    padding-bottom: 100px
    .head
      margin: 10px auto
      text-align: center
      font-size: 16px
    .bodys
      padding: 10px
      .body
        margin: 15px 0
      .submit
        display: flex
        align-items: center
        justify-content: space-between
      .tips
        padding: 10px 0
        display: flex
        justify-content: space-between
        align-items: center
</style>