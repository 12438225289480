<template>
  <div>
      <HomeHead></HomeHead>
      <img src="https://filechain.oss-cn-beijing.aliyuncs.com/faxswapimg/pic003.jpg" alt="">
  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
    name: 'Activity',
    components: {
    HomeHead

  }

}
</script>

<style lang="sass" scoped>
img
  width: 100%
div
  padding-bottom: 86px
  background: #000  

</style>