<template>
	<div class="mbody">
		<HomeHead></HomeHead>
		<div class="top1">
			<div class="title">
				<img src="../assets/images/income/icon-01.png">
				质押挖矿
			</div>
		</div>
		<div class="content">
			<div class="c-top">
				<div class="c-top-left">
					<div class="c-top-img">
						<img class="icon-img1" src="../assets/images/income/icon-fax.png">
					</div>
					<div class="c-top-text">
						FAX收益
						<div v-if="balance1 === 0" class="button">
							提取
						</div>
						<div v-else class="button bg-active" @click="beforeWithdraw">
							提取
						</div>
					</div>
					<div class="c-top-num text-over">
						{{ balance2 }}
					</div>
				</div>
				<div class="c-top-left">
					<div class="c-top-img">
						<img class="icon-img2" src="../assets/images/home/icon2.png">
					</div>
					<div class="c-top-text">
						FS收益
						<div v-if="balance2 === 0" class="button">
							提取
						</div>
						<div v-else class="button bg-active" @click="beforeWithdraw">
							提取
						</div>
					</div>
					<div class="c-top-num text-over">
						{{ balance1 }}
					</div>
				</div>
			</div>
			<div class="c-content">
				<div class="c-c-top">
					<div class="c-c-top-left">
						<img style="" class="icon-img2" src="../assets/images/home/icon2.png">
						<img class="icon-img1 img2" src="../assets/images/income/icon-fax.png">
					</div>
					<div class="c-c-top-right">
						<div v-if="show1" class="cctr-btn" @click="beforeApprove">
							授权
						</div>
						<div v-else>
<!--							<img class="icon-img-btn" v-if="false" src="../assets/images/income/icon-reduce.png" @click="reduceShow = !reduceShow">-->
              <img class="icon-img-btn"  src="../assets/images/income/icon-reduce.png" @click="reduceShow = !reduceShow">
							<img  class="icon-img-btn" src="../assets/images/income/icon-increse.png"
							@click="show = !show">
						</div>
					</div>
				</div>
				<div class="c-c-mid">
					<div class="c-c-mid-con">
						FAX/WUSDT LP挖矿
					</div>
					<div class="c-c-mid-con mar-left">
						矿池总质押量
					</div>
				</div>
				<div class="c-c-mid-num">
					<div class="c-c-mid-connum text-over">
						{{balance3}}
					</div>
					<div class="c-c-mid-connum text-over mar-left">
						<!-- {{balance4}} -->{{totalqty}}
					</div>
				</div>
				<div class="c-c-bot">
					<div class="sub-text">
						当日矿池产出
					</div>
					<div class="c-c-bot-con">
						<div class="ccbc">
							<div class="bt-img-d">
								<img class="bt-img1" src="../assets/images/income/icon-fax.png">
							</div>
							<div class="text-bot">
								<div class="sub-text">
									FAX
								</div>
								<div class="c-c-mid-connum sub-text-bot text-over">
									{{faxPro}}
								</div>
							</div>
						</div>
						<div class="ccbc">
							<div class="bt-img-d">
								<img class="bt-img2" src="../assets/images/home/icon2.png">
							</div>
							<div class="text-bot">
								<div class="sub-text text-bot">
									FS
								</div>
								<div class="c-c-mid-connum sub-text-bot text-over">
									{{fsPro}}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="bot-btn">
				<div class="bot-btn-box border-right" @click="withdrawAll">
					解除质押
				</div>
				<div class="bot-btn-box" @click="toExchange">
					添加流动性
				</div>
			</div>
		</div>
		<Tabs :select="3"></Tabs>
		<el-drawer
		  size="70%"
		  direction="btt"
		  :visible.sync="mydrawer"
		  :with-header="false"
		>
		  <div class="mydrawer">
		    <h4>确认信息</h4>
		    <h2>总金额：</h2>
		    <p>{{ balance1 }} FAX</p>
		    <h2>合约地址：</h2>
		    <p>{{show3 === 2 ? MasterChefContractAddress : ApprovalLPContractAddress}}</p>
		    <h2>操作地址：</h2>
		    <p>{{walletAddress}}</p>
				<div style="text-align: center;">
					<van-button type="info" size="medium" @click="confirm">确认</van-button>
				</div>
		  </div>
		</el-drawer>
		<van-overlay :show="show">
		  <div class="overlay">
		    <div class="ibox">
		      <div class="ihead">质押 FAX/WUSDT LP</div>
		      <div class="ibody">
		        <div class="inputbox">
		          <input type="number" v-model="number1" name="" value="" />
		        </div>
		        <div class="btnbox">
		          <button type="button" @click="number1 = balance4">MAX</button>
		        </div>
		      </div>
		      <div class="yue">{{balance4}} FAX/WUSDT LP 余额</div>
		      <div class="btn2box">
		        <button class="bottom-btns" type="button" @click="show = !show">
		          取消
		        </button>
		        <button class="bottom-btns" type="button" @click="deposit">
		          确认
		        </button>
		      </div>
		    </div>
		  </div>
		</van-overlay>
		<van-overlay :show="reduceShow">
		  <div class="overlay">
		    <div class="ibox">
		      <div class="ihead">提取收益</div>
		      <div class="ibody">
		        <div class="inputbox">
		          <input type="number" v-model="number2" name="" id="" value="" />
		        </div>
		        <div class="btnbox">
		          <button type="button" @click="number2 = balance3">MAX</button>
		        </div>
		      </div>
		      <div class="yue">{{balance3}} FAX/WUSDT LP 余额</div>
		      <div class="btn2box">
		        <button class="bottom-btns" type="button" @click="reduceShow = !reduceShow">
		          取消
		        </button>
		        <button class="bottom-btns" type="button" @click="withdrawPart">
		          确认
		        </button>
		      </div>
		    </div>
		  </div>
		</van-overlay>
	</div>
</template>

<script>
	import Tabs from "../components/tabs.vue";
	import HomeHead from "../components/homehead.vue";
	import BigNumber from "../assets/bignumber.js";
	import {
	  MasterChefABI,
	  TokenLPApprovalABI,
	  ApproveABI,
	} from "../assets/config.js";
	let walletAddress = "";
	let Web3 = require("web3");

	let masterChefInstance;
	let approvalLPInstance;
	export default {
	  name: "Income",
	  components: {
	    Tabs,
	    HomeHead,
	  },
	  data() {
			console.log('======' + '界面传递参数为')
			console.log(this.$route.params)
	    return {
	      master_address: '0xe27efcdbf6c46dcda60f3016cf95355541202db5',
	      approval_address: '0x181577AeB1696e80a395e9C4C40fa7A8743E7260',
				MasterChefContractAddress: this.$route.params.stakingContractAddress,
				ApprovalLPContractAddress: this.$route.params.lpContractAddress,
	      opreatAddr: walletAddress,
				masterChefInstance: {},
				approvalLPInstance: {},
	      mydrawer: false,
	      drawer: false,
	      balance1: 0,
	      balance2: 0,
	      balance3: 0,
				balance4: 0,
	      earned: "",
	      show: false,
	      show1: true,
	      walletAddress: '',
	      poolPid: 0,
	      show3: 0,
	      timeInterval: null,
				timeInterval1:null,
	      amount: 0,
				number1:'',
				number2:'',
				faxPro:0,
				fsPro:0,
				totalqty:0,
				reduceShow:false
	    };
	  },
	  created() {
	    this.getdata();
	  },
		beforeDestroy() {
			clearInterval(this.timeInterval);
			clearInterval(this.timeInterval1)
		},
	  methods: {
	    async getdata() {
				this.fsPro = (60273.9/730).toFixed(4)
			  this.getTime();
	      //质押的poolId
	      this.poolPid = 0;
	      let web3 = new Web3(
	        new Web3.providers.HttpProvider("http://api.faxchain.org")
	      );
	      console.log("加载 1 " + this.walletAddress);
	      if (window && window.ethereum) {
	          web3 = new Web3(window.ethereum);
	        try {
	          let addres = await window.ethereum.enable();
	          this.walletAddress = addres[0];
	          console.log("努力的加载 2 " + this.walletAddress);
	          // window.ethereum.enable().then(res => {
	          // 	console.log("enable success")
	          // 	console.log(res)
	          // 	walletAddress = res
	          // });
	        } catch (error) {
	          console.log(error);
	          console.log("get web3 error");
	          return;
	        }
	      } else {
	        console.log("不处于一个非常好的环境");
	        return;
	      }
	      console.log("加载 3 " + this.walletAddress);
	      this.masterChefInstance = new web3.eth.Contract(
	        MasterChefABI,
	        this.MasterChefContractAddress
	      );
	      console.log("加载 LP " + this.MasterChefContractAddress);
	      console.log('合约1：',   this.masterChefInstance);
				console.log("ApprovalLPContractAddress ",this.ApprovalLPContractAddress)
	      this.approvalLPInstance = new web3.eth.Contract(
	        TokenLPApprovalABI,
	        this.ApprovalLPContractAddress
	      );
	      console.log('合约2：',  this.approvalLPInstance);
	      //用户质押信息，
	      //console.log(this.opreatAddr);
	      await this.userInfo(this.poolPid,this.walletAddress);
	      //返回收益
	      await this.pendingSushi(this.poolPid, this.walletAddress);
	      //查询MasterChef授权金额
	      console.log("查询MasterChef授权金额");
				await this.allowance(this.walletAddress, this.MasterChefContractAddress)
	      //余额
	      await this.balance(this.walletAddress);
				//查询总质押量
				await this.getQty(this.MasterChefContractAddress);
				//定时查询余额对比判断是否已经授权
	      let that = this;
				if(this.show1){
					this.timeInterval = setInterval(function () {
						that.allowance(that.walletAddress, that.MasterChefContractAddress);
					}, 5000);
				}


	      // console.log("授权给MasterChef")
	      //授权给MasterChef
	      // this.approve(ApprovalLPContractAddress, MasterChefContractAddress)
	      //再次查询MasterChef授权金额
	      // console.log("再次查询MasterChef授权金额")
	      // this.allowance(walletAddress, MasterChefContractAddress)
	      //质押
	      // this.deposit(MasterChefContractAddress,poolPid,10)
	      //提取  amount 为0即表示只提取收益
	      // this.withdraw(MasterChefContractAddress,poolPid,1)
	      //提取  amount 为0即表示只提取收益
	      // this.withdraw(MasterChefContractAddress,poolPid,0)
	    },
	    //余额
	    balance(address) {
				console.log('======开始查询balance余额:')
	      this.approvalLPInstance.methods.balanceOf(address).call()
	      .then(res => {
	        console.log('======当前balance余额:', res);
          this.balance4 = parseFloat(this.formatDecimal(res/Math.pow(10,18),6))
        },res =>{
					console.log('======查询balance余额返回错误信息:', res);
					console.log(res)
				})

	    },
			getQty(address){
				this.approvalLPInstance.methods.balanceOf(address).call()
				.then(res => {
					console.log("查询总质押量返回结果" + res)
				  this.totalqty = (res/Math.pow(10,18)).toFixed(4)
				},res =>{
					console.log('======查询总质押量返回错误信息:', res);
					console.log(res)
				})
			},
	    confirm() {
	      //console.log("确认质押");
	      if (this.show3 == 1) {
	        this.approve();
	      } else if (this.show3 == 2) {
	        this.withdraw();
	      }
	    },
	    beforeApprove() {
	      this.mydrawer = true;
	      this.show3 = 1;
	    },
	    beforeWithdraw() {
	      this.mydrawer = true;
	      this.show3 = 2;
	    },
	    //用户质押信息，
	    userInfo(poolPid, address) {
	      console.log("==========执行用户信息");
	      this.masterChefInstance.methods
	        .userInfo(poolPid, address)
	        .call()
	        .then((data) => {
	          console.log("userInfo  - data: ", JSON.stringify(data));
            this.balance3 = parseFloat(this.formatDecimal(data[0]/Math.pow(10,18),6))
	        });
	    },
	    //返回收益
	    pendingSushi(poolPid, address) {
	      console.log("==========执行用户返回收益");
	      this.masterChefInstance.methods
	        .pendingSushi(poolPid, address)
	        .call()
	        .then((data) => {
	          console.log("pendingSushi				data: ", JSON.stringify(data));
            let b1 = data["1"]/Math.pow(10, 18) * 1
            let b2 = data["0"]/Math.pow(10, 18) * 1
            this.balance2 = parseFloat(b2.toFixed(8));
            this.balance1 = parseFloat(b1.toFixed(8));
	        });
	    },
	    toApprove() {
	      let encodeData = this.approvalLPInstance.methods
	        .approve(this.ApprovalLPContractAddress, this.MasterChefContractAddress)
	        .encodeABI();
	       window.ethereum
	        .request({
	          method: "eth_sendTransaction",
	          params: [
	            {
	              from: this.walletAddress,
	              to: this.ApprovalLPContractAddress,
	              value: "0x0",
	              // gasPrice: '0x09184e72a000',
	              // gas: '0x2710',
	              data: encodeData,
	            },
	          ],
	        })
	        .then((txHash) => console.log(txHash))
	        .catch((error) => console.error);
	    },
			withdrawPart(){
				if(this.number2 > this.balance3){
					this.$message("提取金额不能大于剩余金额");
					return
				}
				if(this.number2){
					console.log(this.number2)
					this.withdraw(this.number2)
				}else{
					this.$message("请输入正确的提取金额");
				}
			},
			withdrawAll(){
	     /* this.showToast()
	      return false*/
				if(this.balance3){
					this.withdraw(this.balance3)
				}else{
					this.$message('FAX/WUSDT LP 余额为0!');
				}

			},
	    withdraw(amount1) {
				let options = {
					text:'正在提取收益...'
				}
				this.$loading(options)
	      let contractAddress = this.MasterChefContractAddress;
	      let amount ;
				if(amount1){
					amount = amount1
				}else{
					amount = this.amount
				}
				const decimals = new BigNumber("1000000000000000000")
	      let withdrawAmount = new BigNumber(amount).multipliedBy(decimals);
	      withdrawAmount = withdrawAmount.toFixed(0);
	      console.log(withdrawAmount);
	      let encodeData = this.masterChefInstance.methods
	        .withdraw(this.poolPid, withdrawAmount)
	        .encodeABI();
	      window.ethereum
	        .request({
	          method: "eth_sendTransaction",
	          params: [
	            {
	              from: this.walletAddress,
	              to: this.MasterChefContractAddress,
	              value: "0x0",
	              // gasPrice: '0x09184e72a000',
	              // gas: '0x2710',
	              data: encodeData,
	            },
	          ],
	        })
	        .then((txHash) => {
						console.log()
						console.log(txHash)
					setTimeout(() =>{
						//if(plus){
							this.$loading().close()
							this.number2 = 0
							this.$message('收益提取成功!');
							//plus.nativeUI.closeWaiting()
							//plus.nativeUI.toast('收益提取成功！')
						//}
						this.mydrawer = false
						this.reduceShow = false
					},1000)

					})
	        .catch((error) => {console.error
					this.$loading().close()
					this.$message('收益提取失败!');
					this.mydrawer = false
					});
	    },
	    allowance(owner, spender) {
	      console.log("开始查询授权金额");
	      this.approvalLPInstance.methods
	        .allowance(owner, spender)
	        .call()
	        .then((data) => {
	          console.log("allowance				data: ", data);
	          if (data * 1 > 10 * Math.pow(10, 18)) {
	            this.show1 = false;
	            clearInterval(this.timeInterval);
							let that = this
							this.timeInterval1 = setInterval(function(){
								that.pendingSushi(that.poolPid,that.walletAddress)
								that.userInfo(that.poolPid,that.walletAddress)
								that.balance(that.walletAddress)
							},5000)
	          }
	        });
	    },
	    approve() {
				const _balance2 = new BigNumber(
					"1150000000000000000000000000000000000000000000000000000000000");

					console.log('_balance2' + _balance2)
					console.log(this.walletAddress)
					console.log(this.approvalLPInstance)
					console.log(this.MasterChefContractAddress)

	      let encodeData = this.approvalLPInstance.methods
	        .approve(this.MasterChefContractAddress
					, _balance2.toFixed(0))
	        .encodeABI();
	      window.ethereum
	        .request({
	          method: "eth_sendTransaction",
	          params: [
	            {
	              from: this.walletAddress,
	              to: this.ApprovalLPContractAddress,
	              value: "0x0",
	              // gasPrice: '0x09184e72a000',
	              // gas: '0x2710',
	              data: encodeData,
	            },
	          ],
	        })
	        .then((txHash) => {
	          console.log('==================txHashtxHashtxHashtxHash' + txHash)
						console.log(!txHash)
	          if(!txHash || ''==txHash){
							this.$message("授权失败");
						}else{
							this.$message("授权成功");
							this.show1 = false;
						}
	         this.mydrawer = false;
	        })
	        .catch((error) => {
						this.$message('授权失败!');
						this.mydrawer = false;
					});
	      // let gasLimit = 80000;
	      // let gasPrice = "1";//1Gwei
	      // let txData = {
	      // 	from: walletAddress,
	      // 	to: contractAddress,
	      // 	// gasLimit: web3.utils.toHex(gasLimit),
	      // 	// gasPrice: web3.utils.numberToHex(web3.utils.toWei(gasPrice,"gwei")),
	      // 	value: '0x0',
	      // 	data: encodeData
	      // }

	      // let sendData = {
	      // 	name: "signTransaction",
	      // 	object: txData
	      // }

	      // let payload = {
	      // 	method: "eth_sendTransaction",
	      // 	params: txData,
	      // 	from: walletAddress
	      // }

	      // console.log(payload)

	      // window.ethereum.sendAsync(payload, function(err, response) {
	      // 	const rejected = 'User denied transaction signature.'
	      // 	if (response.error && response.error.message.includes(rejected)) {
	      // 		return alert(`We can't take your money without your permission.`)
	      // 	}

	      // 	if (err) {
	      // 		return alert('There was an issue, please try again.')
	      // 	}

	      // 	if (response.result) {
	      // 		//如果存在response.result，那么调用就是成功的
	      // 		//在这种情况下，它就是交易哈希
	      // 		const txHash = response.result
	      // 		alert('Thank you for your generosity!')

	      // 		//你可以轮询区块链来查看交易何时被打包进区块
	      // 		// pollForCompletion(txHash, callback)
	      // 	}
	      // })
	      // approvalLPInstance.methods.approve(spender, balance).send(txInfo).then(data => {
	      // 	console.log('approve				data: ', data);
	      // });
	    },
	    deposit() {
	      let contractAddress = this.MasterChefContractAddress;
	      let poolPid = this.poolPid;
	      let amount = 10;
				if(!this.number1 || this.number1 == 0){
					alert('质押金额不能为0或空')
					return
				}else if(this.number1 > this.balance4){
					alert('质押金额不能超过余额')
					return
				}

				let options = {
					text:'正在操作......'
				}
				this.$loading(options)
				// plus && plus.nativeUI.showWaiting('正在操作......')
	      const decimals = Math.pow(10,18);
	      let depositAmount = new BigNumber(this.number1).multipliedBy(decimals);
	      depositAmount = depositAmount.toFixed(0);

	      let encodeData = this.masterChefInstance.methods
	        .deposit(poolPid, depositAmount)
	        .encodeABI();
	      window.ethereum
	        .request({
	          method: "eth_sendTransaction",
	          params: [
	            {
	              from: this.walletAddress,
	              to: contractAddress,
	              value: "0x0",
	              // gasPrice: '0x09184e72a000',
	              // gas: '0x2710',
	              data: encodeData,
	            },
	          ],
	        })
	        .then((txHash) => {console.log(txHash)

					setTimeout(() =>{
						//plus && plus.nativeUI.toast('质押成功')
						//plus && plus.nativeUI.closeWaiting()
						this.balance(this.walletAddress);
						this.userInfo(this.poolPid,this.walletAddress);
						this.$loading().close()
						this.$message('质押成功!');
						this.show = false
						this.number1 = ''
					},2000)
					})
	        .catch((error) => {
						this.$loading().close()
						this.$message('质押失败!');
						this.show = false
						this.number1 = ''
					});
	    },
			toExchange(){
				this.$router.push({ name: 'Exchange', params: {}})
			},
			_isMobile() {
			      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			      return flag;
			    },
			getTime(){
				let startTime = new Date('2021-08-13'); // 开始时间
				let endTime = new Date(); // 结束时间
				console.log('startime:', startTime / 1000 / 60 / 60 / 24);
				console.log('endtime:', endTime / 1000 / 60 / 60 / 24);
				let days = Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24);
				console.log('days:', days)
				this.faxPro = (9727 * Math.pow(1.001,days)).toFixed(2)
			},
      formatDecimal(num, decimal) {
        let newNum = Math.pow(10,decimal)
        return parseFloat(Math.floor(num * newNum) / newNum)
      },
      showToast(){
        this.$message("暂时不能解除质押");
      }
	  },
		computed:{
			show4(){
				return !(this.balance1 && this.balance2)
			}
		}
	}
</script>

<style scoped>
	@import '../assets/css/income.css' ;
	.bg-active{
		background-color: #F94A5E;
	}
	.mydrawer{
		padding: 0.5rem  0;
	}
	.mydrawer h4{
		  margin: .2rem 0;
		  font-size: 0.4rem;
		  font-weight: 700;
		  text-align: center;
	}
	.mydrawer p{
		padding: .2rem .3rem;
		width: 95%;
		overflow: hidden!important;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.mydrawer h2{
		display: block;
		padding: .2rem;
		font-size: .3rem;
		color: #bbb;
	}
	.van-button{
		margin: 1rem auto;
		width: 4rem;
	}
	.overlay{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	.ibox{
		padding: .3rem;
		border-radius: .3rem;
		text-align: center;
		background-color: #FFFFFF;
	}
	.ihead{
		font-size: .4rem;
		font-weight: 600;
	}
	.ibody{
		display: flex;
		align-content: center;
		box-sizing: border-box;
		background-color: #f3f4f6;
		padding: 10px;
		margin-top: 20px;
		line-height: 20px;
	}
	.inputbox{
		background: #f3f4f6;
		width: 70%;
		line-height: 30px;
	}
	.inputbox input{
		background: #f3f4f6;
		width: 100%;
		line-height: 30px;
		font-size: 25px;
		font-weight: 600;
	}
	.btnbox{
		background: #f3f4f6;
		text-align: right;
		width: 30%;
	}
	.btnbox button{
		line-height: 30px;
		background: #07c4a8;
		padding: 2px 10px;
		border-radius: 5px;
		color: #ffffff ;
	}
	.yue{
		text-align: right;
		color: #c0c4cc;
		line-height: 30px;
		margin: 10px 0 20px 0;
	}
	.btn2box{
		display: flex;
		justify-content: space-between;
	}
	.btn2box button{
		width: 45%;
		line-height: 36px;
		border-radius: 2px;
		color: #909399;
		font-weight: 600;
	}

</style>
