import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementUI from 'element-ui'
import Vant from 'vant'
import web3 from 'web3'


import 'element-ui/lib/theme-chalk/index.css'
import 'vant/lib/index.css'

Vue.use(ElementUI)
Vue.use(Vant)

// axios.defaults.baseURL= 'http://explorer.filechain.pro/strokeApi'
// axios.defaults.baseURL= 'http://www.faxscan.org/strokeApi'

// 线上：
axios.defaults.baseURL= 'http://15.197.147.50:18090/strokeApi'

// axios.defaults.baseURL= 'http://api.faxscan.io/strokeApi' 

// 测试地址:解
// axios.defaults.baseURL= 'http://192.168.10.201:11301/chain-explorer-api'

//axios.defaults.baseURL='http://47.118.53.248:18080/strokeApi' 
// axios.defaults.baseURL='http://47.118.53.248:36659'
axios.interceptors.request.use(config => {
  return config
})
axios.interceptors.response.use(config => {
  return config
})

Vue.prototype.$axios= axios
Vue.prototype.bus = new Vue()


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
