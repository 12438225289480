<template>
  <div>
      <HomeHead></HomeHead>
      <div class="box">

          <h2>公 告</h2>
          <div class="line"></div>
          <h4>以下是本节中的文章 :</h4>
          <!-- <h6 @click="toPage('/Notice9')">关于社区算力空投结束的公告</h6> -->
          <h6 @click="toPage('/Notice8')">FaxSwap关闭TYT跨链映射公告</h6>
          <h6 @click="toPage('/Notice7')">FaxSwap新增DogMoon跨链和流动性挖矿公告</h6>
          <h6 @click="toPage('/Notice6')">FaxSwap新增TYT流动性挖矿公告</h6>
          <h6 @click="toPage('/Notice5')">关于FaxSwap短暂数据出错的公告</h6>
          <h6 @click="toPage('/Notice4')">FaxSwap新增DXC流动性挖矿公告</h6>
          <!-- <h6 @click="toPage('/Notice3')">关于“FAX马拉松”矿工竞赛的开启公告</h6> -->
          <!-- <h6 @click="toPage('/Notice2')">关于社区算力空投的开启公告</h6> -->
          <h6 @click="toPage('/Notice')">关于FaxSwap和双子池流动性挖矿的开启公告</h6>
      </div>
      

  </div>
</template>

<script>
import HomeHead from "../components/homehead.vue";
export default {
    name:'Notices',
	components: {
	  HomeHead
	},
    methods: {
        toPage(pa) {
            this.$router.push(pa)
        },
        // toNotice2() {
        //     this.$router.push('/Notice2')
        // },
        // toNotice3() {
        //     this.$router.push('/Notice3')
        // },
        // toNotice4() {
        //     this.$router.push('/Notice4')
        // },
        // toNotice5() {
        //     this.$router.push('/Notice5')
        // }
    }

}
</script>

<style lang="sass" scoped>
.box
  padding: 20px 20px 100px
  h2  
    margin-bottom: 35px
    font-size: 20px  
    text-align: center
  .line
    width: 100%
    height: 1px
    background: #ddd
  h4  
    margin: 50px auto 40px
    font-size: 16px
    font-weight: 700
  h6  
    margin-bottom: 30px
    padding: 25px 10px
    font-size: 15px
    //font-weight: 700
    border:1px solid #ddd
    border-radius: 2px
    box-shadow: 1px 1px 9px rgba(0,0,0,.2)
   
</style>