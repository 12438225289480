<template>
	<div id="">
		<HomeHead>
		</HomeHead>

		<div class="padding" style="padding-bottom: 100px;">
			<div class="head bold">
				FAQ
			</div>
			<hr />
			<div>
				<h2 class="bold margin-top">1.钱包一直链接不上，流动性添加不确认</h2>
				<h3 class="">钱包无法连接成功，可以退出钱包重新登录FaxSwap而得到改善。</h3>
				<h2 class="bold margin-top">2.FaxSwap为什么出现了交易失败</h2>
				<h3 class="">有可能是节点延迟，造成获取价格有误差，本身价格波动大，当前的数量和提交的那一刻所能获得的数量有误差，也有可能是有最佳的路径，但是用户已经提交。</h3>
				<h2 class="bold margin-top">3.用户跨链桥出现的延迟到账</h2>
				<h3 class="">出现未及时到账情况可尝试退出重新登录，查看钱包链接是否正常。如依然未到账请通过官方邮件提交交易哈希值，工单处理。官方邮箱：faxchainorg@gmail.com</h3>
				<h2 class="bold margin-top">4.交易手续费</h2>
				<h3 class="">在FaxSwap上的任何一笔交易都会产生国际惯例0.3%的手续费，产生的手续费将统一由智能合约来回购交易所的FS。</h3>
				<h2 class="bold margin-top">5.WUSDT是什么？</h2>
				<h3 class="">WUSDT（Wrapped USDT）是一种和波场链USDT锚定的FRC-20代币。WUSDT是在FaxChain上合约托管而发行的，实现与波场链USDT以1:1锚定，所有发行的WUSDT都会得到波场链USDT的支持，每个波场链USDT都能够与发行的WUSDT在算法的基础上总数对应，并且可在链上验证，只有经链上合约才能铸造WUSDT，而当持有人将WUSDT兑换成波场链USDT时，WUSDT将被等量销毁。</h3>
				<h2 class="bold margin-top">6.NFT、合约领域有没有引入协议的计划？</h2>
				<h3 class="">关于NFT、合约板块的引入未来大概率都会实现，但需要随着生态的发展来实现。如果有运营策略的调整或者推进都会通过官方公告发出，敬请期待。</h3>
				<h2 class="bold margin-top">7.为什么流动性挖矿质押LP，而基金会质押FS？</h2>
				<h3 class="">流动性挖矿是矿池合约中的出块奖励，取得奖励的要求是添加交易池中的币种流动性，从而扩大交易池的体量；而基金会是手续费分红奖励，质押的FS相当于用户持有的交易所股权证明一样，形式和奖励来源都不一样。</h3>
				<h2 class="bold margin-top">8.FaxSwap治理币FS的价值和上涨机制？</h2>
				<h3 class="">FaxSwap会把所有的交易手续费用于二级市场回购FS，当触发回购条件时，由智能合约自动执行回购并注入基金会分红池。同时，所有用户可以质押FS的形式获取基金会分红池的奖励。</h3>
				<h2 class="bold margin-top">9.FaxSwap官网的年APY收益是否复利?</h2>
				<h3 class="">不是的，FaxSwap官网上的APY收益显示的是单次收益。</h3>
				<h2 class="bold margin-top">10.FAX/FS/WUSDT转账错误到代币合约地址</h2>
				<h3 class="">所有误转入合约地址的代币项目方都没办法追回，合约并未留有转出代币的方式，非常抱歉。</h3>
				
			</div>
		</div>
	</div>
</template>

<script>
	import HomeHead from "../components/homehead.vue";
	export default {
		name:'FaxSwapCourse',
		components: {
		  HomeHead
		}
	}
</script>

<style>
	.head {
		text-align: center;
		font-size: 20px;
		padding: 30px;
	}

	.bold {
		font-weight: bold;
	}

	.padding {
		padding: 20px;
	}
	.margin-top{
		margin-top: 2em;
	}
	h2
	 {
		line-height: 3em;
	}
	h3{
		line-height: 2em;
	}
</style>
