<template>
  <div>
    <HomeHead></HomeHead>
    <!-- <Head>
      <template v-slot:connect>
        <div class="kind">
          <select name="" id="">
            <option value="">ETH</option>
            <option value="">BSC</option>
            <option value="">HECO</option>
          </select>
        </div>
      </template>
    </Head> -->
    <!-- <iframe
      id="mobsf"
      src="http://47.118.53.248:18087/indexweb.html#/swap"
      frameborder="0"
    ></iframe> -->
    <Tabs></Tabs>
  </div>
</template>


<script>
import { uniappBridgeJs } from "../assets/uni.webview.0.1.52.js";
import HomeHead from "../components/homehead.vue";
import Tabs from "../components/tabs.vue";
import Head from "../components/head.vue";

export default {
  name: "Exchange",
  components: {
    Tabs,
    //Head,
    HomeHead,
  },
  data() {
    return {};
  },
  // beforeRouteEnter: (to, from, next) => {
  // 		next(vm => {
  // 			if(from.path == '/home'||from.path == '/mine'|| from.path == '/pool'|| from.path == '/foundation') {
  // 				window.location.href = "http://faxswap.top"
  // 			}
  // 			else {
  // 				next()
  // 			}
  // 		})

  // },
  created() {
    // window.location.href = "http://faxswap.top"
    // window.location.href = "http://vwghuiw.faxswap.info"
    // window.location.href = "http://exchange.faxswap.io"
    // window.location.href = "http://exchange001.faxswap.net"

    this.$axios
      .post(
        "http://15.197.147.50:18090/strokeApi/common/get_normal_config?name=FAXSWAP_EXCHANGE_DAPP_URL"
      )
      .then((res) => {
        console.log(",,,,,,,,,,", res);
        window.location.href = res.data.message;
      }); 
  },
  mounted() {
    if (typeof uni !== 'undefined') {
      uni.postMessage({
        data: {
          action: "curWebRefresh",
        },
      });
    } else if ( typeof uniLittleBridge !== 'undefined') {
      uniLittleBridge.postMessage({
        data: {
          action: "curWebRefresh",
        },
      });
    }

    /* document.addEventListener('UniAppJSBridgeReady', function() {
			       console.log('初始化UniAppJSBridgeReady成功')
			        uni.postMessage({
			            data: {
			                action: 'curWebRefresh'
			            }
			        });
			    }); */
  },
};
</script>

<style lang="" scoped>
</style>
