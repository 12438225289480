<template>
  <div class="blocks">
    <HomeHead></HomeHead>
    <div class="block">
      <!-- 头部 -->
      <div class="head">LP挖矿审核</div>

      <!-- 申请展示 -->
      <div class="bodys">
        <div class="page">
          <div
            @click="toPage(i)"
            class="item"
            v-for="(i, index) of ruleForm.content"
            :key="index"
          >
            <div class="i1">{{ index + 1 }}</div>
            <div class="i2">
              <div class="i21">
                <div class="i211">
                  {{ i.tokenNameOne }}/{{ i.tokenNameTwo }}
                </div>
                <div>{{ i.contactPhone }}</div>
              </div>
              <div class="i22">
                <div class="i222">
                  {{ i.tokenSymbolOne }}/{{ i.tokenSymbolTwo }}
                </div>
                <div>{{ i.email }}</div>
              </div>
            </div>
            <div class="i3">
              <div class="i31">已提交</div>
            </div>
          </div>
        </div>

        <div class="tips">
          <!-- <p>
            申请审核需7个工作日，若审核通过，FAX审核团队将会与您电话沟通进行进一步评估。
          </p> -->
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="iquery.pageNum"
            :page-size="iquery.pageSize"
            layout="prev, pager, next"
            :total="ruleForm.totalPages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
let Web3 = require("web3");
import HomeHead from "../components/homehead.vue";
export default {
  components: {
    HomeHead,
  },
  data() {
    return {
      ruleForm: [],
      iquery: {
        auditStatus: "",
        applicantContractAddress: "",
        pageNum: 1,
        pageSize: 10,
      }
    };
  },
  async created() {
    // 获取钱包地址
    let web3 = new Web3(window.ethereum);
    let addres = await window.ethereum.enable();
    this.iquery.applicantContractAddress = addres[0];
    console.log(
      "-----applicantContractAddress------",
      this.iquery.applicantContractAddress
    );
    // 获取申请状态码
    // this.iquery.auditStatus = this.$route.query.iquery;
    // console.log("-------------auditStatus-----------", this.iquery.auditStatus);
  },
  mounted() {
    let t = setInterval(() => {
      if(this.iquery.applicantContractAddress) {
        clearInterval(t)
      }
      this.getData()
    }, 200);
  },
  methods: {
    getData() {
      this.$axios.post("/lpApplication/page/list", qs.stringify(this.iquery))
      .then((res) => {
        console.log("----------res-----------", res);
        if (res.data.status == 200) {
          this.ruleForm = res.data.data;
          // this.$message.success("提交成功!");
          return;
        }
        this.$message.error("获取失败!");
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.iquery.pageNum = val
      this.getData()
    },
    toPage(i) {
        console.log('--------i---------', i);
        this.$router.push({
            path: '/applyLPDetail',
            query: {
                idata: i
            }
        })
    }
  },
};
</script>

<style lang="sass" scoped>
.blocks
  .block
    padding: 10px
    padding-bottom: 100px
    .head
      margin: 10px auto
      text-align: center
      font-size: 16px
    .bodys
      padding: 10px
      .page
        .item
          display: flex
          align-items: center
          padding: 10px 5px
          margin: 20px 0
          border: 1px solid #e5e9f2
          background: #fff
          .i1
            width: 3%
            text-align: center
          .i2
            width: 82%
            display: flex
            align-items: center
            justify-content: space-around
            .i21
              .i211
                margin-bottom: 5px
            .i22
              .i222
                margin-bottom: 5px
          .i3
            width: 15%
            text-align: center
            .i31
              margin-bottom: 5px
              padding: 5px 0
              background: green
              color: #fff
              border-radius: 5px
      .tips
        text-align: center
</style>