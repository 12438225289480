import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/home.vue'
//import Tabs from '@/views/tabs.vue'
//import Exchange from '../views/exchange.vue'
//import Airdrop from '../views/airdrop.vue'
//import Pool from '../views/pool.vue'
//import Calculate from '../views/calculate.vue'

Vue.use(VueRouter)

const routes = [
  {
    redirect: '/home',
    path: '/'
  },
  {
    path: '/exchange/:id',
    name: 'Exchange',
    component: () => import('../views/exchange.vue')
  },
  {
    path: '/pool',
    name: 'Pool',
    component: () => import('../views/pool1.vue')
  },
  {
    path: '/fundation',
    name: 'Fundation',
    component: () => import('../views/fundation.vue')
  },
  {
    path: '/income',
    name: 'Income',
    component: () => import('../views/income1.vue')
  },
	{
	  path: '/income2',
	  name: 'Income2',
	  component: () => import('../views/income2.vue')
	},
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine.vue'),
  },
  {
    path: '/tokenVesting',
    name: 'TokenVesting',
    component: () => import('../views/tokenVesting.vue'),
  },
  //{
  //path: '/about',
  //name: 'About',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  //}

  {
    path: '/airdrop',
    name: 'Airdrop',
    component: () => import('../views/airdrop.vue')
  },
  {
    path: '/FaxSwapCourse',
    name: 'FaxSwapCourse',
    component: () => import('../views/FaxSwapCourse.vue')
  },
  {
    path: '/FAQ',
    name: 'FAQ',
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/Notices',
    name: 'Notices',
    component: () => import('../views/Notices.vue')
  },
  {
    path: '/Notice',
    name: 'Notice',
    component: () => import('../views/Notice.vue')
  },
  {
    path: '/Notice2',
    name: 'Notice2',
    component: () => import('../views/Notice2.vue')
  },
  {
    path: '/Notice3',
    name: 'Notice3',
    component: () => import('../views/Notice3.vue')
  },
  {
    path: '/Notice4',
    name: 'Notice4',
    component: () => import('../views/Notice4.vue')
  },
  {
    path: '/Notice5',
    name: 'Notice5',
    component: () => import('../views/Notice5.vue')
  },
  {
    path: '/Notice6',
    name: 'Notice6',
    component: () => import('../views/Notice6.vue')
  },
  {
    path: '/Notice7',
    name: 'Notice7',
    component: () => import('../views/Notice7.vue')
  },
  {
    path: '/Notice8',
    name: 'Notice8',
    component: () => import('../views/Notice8.vue')
  },
  {
    path: '/Notice9',
    name: 'Notice9',
    component: () => import('../views/Notice9.vue')
  },
  {
    path: '/Scanqr',
    name: 'Scanqr',
    component: () => import('../components/scanqr.vue')
  },
  {
    path: '/Activity',
    name: 'Activity',
    component: () => import('../views/activity.vue')
  },
  {
    path: '/applyLP',
    name: 'applyLP',
    component: () => import('../views/applyLP.vue')
  },
  {
    path: '/applyLPDetail',
    name: 'applyLPDetail',
    component: () => import('../views/applyLPDetail.vue')
  },
  {
    path: '/myLPApply',
    name: 'myLPApply',
    component: () => import('../views/myLPApply.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
