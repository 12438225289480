<template>
  <div class="mbody">
    <HomeHead></HomeHead>
    <Top></Top>
    <!-- <div class="top">
			<div class="top-left">
				<div class="text1">
					<span>玩转</span>
					<span>DeFi</span>
				</div>
				<div class="text2">
					<span>启动</span>
					<span>FaxSwap</span>
				</div>
				<div class="text3">
					<span>FAX</span>
					<span>流动性挖矿</span>
				</div>
				<div class="text4">
					<span>开启寻金之旅</span>
				</div>
				
			</div>
			<div class="text5">
				<img src="../assets/images/pool/icon-3.png" >
				<img src="../assets/images/pool/icon-4.png" >
				<img src="../assets/images/pool/icon-4.png" >
			</div>
		</div> -->
    <div class="middle">
      <div class="middle-content">
        <div class="m-top">
          <img src="../assets/images/pool/icon-1.png" />
          <span> 流动性挖矿 </span>
        </div>
        <div class="m-con">
          <div class="m-con-top">
            <img src="../assets/images/pool/icon-2.png" />
            <span> 总锁仓量(USDT） </span>
          </div>
          <div v-for="(i, id) of totalValueLocked " :key="id" class="m-con-con">
            {{ i.totalValueLocked.toFixed(8) }}
          </div>
		  <!-- <div v-for="(i, id) of totalValueLocked " :key="id" class="m-con-con">
            -
          </div> -->
        </div>
      </div>
      <div class="middle-content">
        <div class="m-d-top">
          <div class="">
            交易对 / TVL
            <img src="../assets/images/pool/icon-select.png" />
          </div>
          <div class="">
            APR
            <img src="../assets/images/pool/icon-select.png" />
          </div>
        </div>
        <div class="m-d-con">
          <div
            class="list"
            v-for="(item, index) in plist" 
            @click="toIncome(item)"
			:key="index"
          >
            <div class="list-left">
              <div class="list-name">
                {{ item.symbol }}
              </div>
              <div class="list-qty">{{item.tvlInfo}}</div>
            </div>
            <div class="list-right">
              <div class="l-r-left">{{(item.singletonAnnualizedRate*100).toFixed(2)}} %</div>
              <div class="l-r-right">
                <img src="../assets/images/pool/icon-qustion.png" />
              </div>
            </div>
          </div>
          <div class="list">
            <div class="list-left">
              <div class="list-name">FS/FAX</div>
              <div class="list-qty">-</div>
            </div>
            <div class="list-right">
              <div class="l-r-left">-</div>
              <div class="l-r-right">
                <img src="../assets/images/pool/icon-qustion.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Tabs :select="3"></Tabs>
  </div>
</template>

<script>
	import Tabs from "../components/tabs.vue";
	import HomeHead from "../components/homehead.vue";
	import Top from "../components/top.vue"
	export default{
		name: "Pool1",
		components: {
		  Tabs,
		  HomeHead,
			Top
		},
		data() {
		  return {

		    plist: [],
			selectData:{},
			totalValueLocked: []
		  }

		},
		created() {
		  this.getpool()
		  this.getTotalValueLocked()
		},
		methods: {
		  toIncome(data) {
				console.log(data)
				if(data.symbol !== 'FAX/WUSDT'){
					this.$router.push({ name: 'Income2', params: data})
				}else{
					this.$router.push({ name: 'Income', params: data})
				}
				/* return false
				if(this.compareTime(data.createTime)){
					this.$router.push({ name: 'Income', params: data})
				}else{
					alert('功能暂未开放')
				} */
		    
		  },
		  getpool() {
		    this.$axios.post('/stakingLpMiningConfig/list')
		    .then(res => {
		      console.log('pool', res );
		      if(res.status !== 200) {
		        console.log('获取失败');
		      }
			//暂时修改
			//   res.data.data.forEach(i => {
			// 	  if(i.symbol == 'DXC/FAX') {
			// 		  i.tvlInfo = '-'
			// 	  }
			//   })
		      this.plist = res.data.data
		      console.log('plist:', this.plist);
			  
		    })
		  },
		  getTotalValueLocked() {
			  this.$axios.post('/tvl/getTvl')
			  .then(res => {
				  console.log('totalValueLocked:', res);
				  if(res.data.status == 200) {
					 this.totalValueLocked = res.data.data
				  }else if(res.data.status !== 200) {
					  this.$message.error(res.data.message)
				  }
				  
			  })
		  },
			compareTime(t){
				let t1 = new Date()
				let t2 = new Date(t)
				if(t2 > t1) return false
				return true
			}
		}
	}
</script>

<style>
@import "../assets/css/pool.css";
</style>
