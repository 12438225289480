<template>
  <div>
    <HomeHead></HomeHead>
    <div class="content">
      <div class="top con-body">
        <div class="total">
          <div class="title-to ">锁仓总量</div>
          <div class="number-to">{{ totalLockAmount }} FAX</div>
        </div>
        <div class="top-con">
          <div class="t-c-left">
            <div class="t-c-title ">剩余量（FAX）</div>
            <div class="t-c-num">{{ totalRestAmount }}</div>
          </div>
          <div class="t-c-right">
            <div class="t-c-title">已提取量（FAX）</div>
            <div class="t-c-num">{{ totalReleasedAmount }}</div>
          </div>
        </div>
      </div>
      <div class="middle con-body">
        <div>
          <div class="m-title">
            当前钱包地址:
          </div>
          <div class="m-address">
            <label >{{ walletAddress.substr(0,24) + '...' + walletAddress.substr(walletAddress.length - 5,walletAddress.length)}}</label>
            <i id="copyText" class="el-icon-document-copy " style="margin-right: 10px;" @click="copy"></i>
          </div>
        </div>
        <div class="m-line">
          <div class="m-title">
            已提取
          </div>
          <div>
            {{ userTotalBalance }} FAX
          </div>
        </div>
        <div class="m-line">
          <div class="m-title">
            可提取
          </div>
          <div>
            {{ userReleasedBalance }} FAX
          </div>
        </div>
        <div class="m-line">
          <div class="m-title">
            上次提取时间
          </div>
          <div>
            {{ lastClaimTime == 0 ? '':lastClaimTime}}
          </div>
        </div>
      </div>
      <div class="bottom-btn">
        <el-button type="primary" :disabled="btnDisabled" :loading="drawing" @click="draw" >提取</el-button>
      </div>
    </div>
  </div>
</template>


<script>
import Clipboard from "clipboard";

let Web3 = require('web3')
let BigNumber = require('bignumber.js')
import MyABI from '../assets/tokenvesting_abi.json'
let web3
let MyContract_Model
//智能合约地址
let Contract_Address = '0xF0eDF45920956f49365D07a5136745F79c5eE3a5'
//币种精度位
let coinDecimal = 18
import HomeHead from "../components/homehead.vue";
import {
  toDate
} from "../assets/dateUtil.js"
export default {
  data() {
    return {
      title: 'Hello',
      walletAddress:'',
      totalLockAmount:'',
      totalReleasedAmount:'',
      totalRestAmount:'',
      userTotalBalance:'',
      userReleasedBalance:'',
      lastClaimTime:'',
      drawing:false,
      btnDisabled:true
    }
  },
  components: {
    HomeHead,
  },
  created() {
    this.initWeb()
  },
  methods: {
    async initWeb(){
      const loading = this.$loading({
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'hsla(0,0%,100%,.9)'
      });
      let a = setInterval(async () => {
        if (typeof window.ethereum !== 'undefined') {
          web3 = new Web3(window.ethereum);
          let addresses = await window.ethereum.request({"method":"eth_requestAccounts"});
          this.walletAddress = addresses[0]
          let currentChainId = window.ethereum.networkVersion
          MyContract_Model = new web3.eth.Contract(MyABI, Contract_Address);
          try{
            let data001 =  await this.checkUser(this.walletAddress)
            let totalLockAmount = await this.getTotalLockAmount()
            this.totalLockAmount = this.keepSix(totalLockAmount)
            let totalReleasedAmount = await MyContract_Model.methods.totalReleasedAmount().call()
            this.totalReleasedAmount = this.keepSix(totalReleasedAmount)
            let totalRestAmount = this.totalLockAmount * 1 - this.totalReleasedAmount * 1
            this.totalRestAmount = parseFloat(totalRestAmount.toFixed(6))
            if(data001){

              let data003 = await this.addressInfo(this.walletAddress)
              this.userTotalBalance = this.keepSix(data003.releasedBalanceOf)
              if(data003.lastClaimTime != 0){
                this.lastClaimTime = toDate(data003.lastClaimTime, false)
              }

              let res = await this.calculateReleasableAmount(this.walletAddress)
              this.userReleasedBalance = this.keepSix(res['0'])
              this.btnDisabled = false
            }
            //console.log('calculateReleasableAmount				data004: ', JSON.stringify(data004));
            loading.close()
            clearInterval(a)
          }catch (e) {
            loading.close()
            clearInterval(a)
          }
        } else {
          console.log("未加载web3环境，请先连接web3环境")
          return;
        }
      }, 2000);
    },
    async checkUser(address){
      let data = await MyContract_Model.methods.checkUser(address).call()
      return data;
    },
    async getTotalLockAmount(){
      let data = await MyContract_Model.methods.totalLockAmount().call()
      return data;
    },
    async addressInfo(address){
      let data = await MyContract_Model.methods.addressInfo(address).call()
      return data;
    },
    async calculateReleasableAmount(address){
      let data = await MyContract_Model.methods.calculateReleasableAmount(address).call()
      return data;
    },
    claim(config){


    },
    loading(){

    },
    keepSix(num){
      return parseFloat((num/Math.pow(10,18)).toFixed(6))
    },
    draw(){
      if(!this.userReleasedBalance){
        this.$message({
          message: '暂无可提取量',
          type: 'error'
        });
        return false
      }
      this.drawing = true
      let config = {
        fromAddress : this.walletAddress,
        toAddress : Contract_Address
      }
      let encodeData = MyContract_Model.methods.claim().encodeABI();
      window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [{
          from: config.fromAddress,
          to: config.toAddress,
          data: encodeData
        }]
      }).then((txHash) => {
        console.log(txHash)
        this.drawing = false
        this.$message({
          message: '提取成功！',
          type: 'success'
        });
        this.initWeb()
      }).catch((error) => {
        console.log(error)
        this.drawing = false
        this.$message({
          message: '提取失败！' + error.message,
          type: 'error'
        });
      });
    },
    copy(){
      let that = this
      let clipboard = new Clipboard('#copyText',{
        text: function (){
          return that.walletAddress
        }
      });
      //console.log('content:',clipboard)
      if (clipboard) {
        this.$message.success("操作成功！");
      }
    }
  }
}
</script>

<style>
.con-body{
  background-color: #FFFFFF;
  padding: 12px 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  font-font: Microsoft YaHei;
}
.top{
  margin: 20px 15px 25px;
}
.middle{
  margin: 0 15px;
  padding-bottom: 0px;
}
.total{
  color: #2C2C2C;
  font-size: 20px;
  border-bottom: 1px solid #DADADA;
}
.title-to{

}
.number-to{
  margin-top: 6px;
  font-weight:bold ;
  margin-bottom: 12px;
}
.top-con{
  display: flex;
  margin-top: 18px;
}
.top-con>div{
  flex: 1;
}
.t-c-title{
  font-size: 10px;
  color: #A9A9A9;
}
.t-c-left{
  border-right: 1px solid #DADADA;
}
.t-c-right{
  padding-left: 10px;
}
.t-c-num{
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #494949;
}

.m-address{
  margin:10px 0 12px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 14px;
}
.m-title{
  font-size: 14px;
  color:#2C2C2C;
}
.m-line{
  display: flex;
  justify-items: center;
  justify-content: space-between;
  height: 46px;
  line-height: 46px;
  border-top: 1px solid #DADADA;
}
.bottom-btn{
  display: flex;
  justify-items: center;
  justify-content: center;
  margin-top: 32px;
  padding: 0 15px;
}
.el-button--primary{
  background-color: #176ECF;
  border-color: #176ECF;
}
 .el-button--primary:focus, .el-button--primary:hover{
  background-color: #176ECF;
  border-color: #176ECF;
}
.el-button--primary.is-disabled, .el-button--primary.is-disabled:active, .el-button--primary.is-disabled:focus, .el-button--primary.is-disabled:hover{
  background-color: rgba(23,110,207,0.6);
  border-color: rgba(23,110,207,0.6);
}
.el-button {
  width: 100%;
}
</style>
