<template>
  <div class="tabs" style="z-index: 999;">
    <router-link tag="div" to="/home">
			<img v-if="select == 1" src="../assets/images/tabs/01on.png" alt="" />
			<img v-else src="../assets/images/tabs/01un.png" alt="" />
      <p>首页</p>
    </router-link>
    <router-link tag="div" :to="'/exchange/' + 33">
			<img v-if="select == 2" src="../assets/images/tabs/02on.png" alt="" />
      <img v-else src="../assets/images/tabs/02un.png" alt="" />
      <p>兑换</p>
    </router-link>
    <router-link tag="div" to="/pool">
      <img v-if="select == 3" src="../assets/images/tabs/03on.png" alt="" />
      <img v-else src="../assets/images/tabs/03un.png" alt="" />
      <p>矿池</p>
    </router-link>
    <router-link tag="div" to="/fundation">
      <img v-if="select == 4" src="../assets/images/tabs/04on.png" alt="" />
      <img v-else src="../assets/images/tabs/04un.png" alt="" />
      <p>基金会</p>
    </router-link>
		<!-- <router-link tag="div" to="/mine">
		  <img v-if="select == 5" src="../assets/images/tabs/05on.png" alt="" />
		  <img v-else src="../assets/images/tabs/05un.png" alt="" />
		  <p>我的</p>
		</router-link> -->
  </div>
</template>

<script>
export default {
  name: "Tabs",
	props:{
			select:Number
	},
	data(){
		//console.log(this.select)
		//console.log(document.documentElement.clientHeight)
		return {}
	}
};
</script>

<style lang="sass" scoped>
.tabs
  display: flex
  justify-content: space-around
  align-items: center
  width: 100%
  height: 50px
  position: fixed
  bottom: 0px
  left: 0
  background: #fff
  .router-link-active
    text-align: center
    color: red

  div
    text-align: center
    img
      margin-bottom: 0.1rem
      width: 20px
      height: 20px
    p   
    font-size: 13px
</style>
