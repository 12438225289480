
// 'f123334....' 转 '0x...'

let bech32 = require('bech32')
import { Buffer } from 'buffer'

export const convertBech32ToHex = (bech32Address) => {

    function buf2hex(buffer) { // buffer is an ArrayBuffer
        return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
    }

    const decodeAddressToBuffer = (addr) => {
        console.log('======bech32=======',bech32);
        const decodedAddress = bech32.decode(addr)
        return Buffer.from(bech32.fromWords(decodedAddress.words))
    }

    const address = decodeAddressToBuffer(bech32Address)
    // console.log('buf2hex:', buf2hex);
    return "0x" + buf2hex(address)
}

