<template>
  <div>
    <!-- <Head>
      <template v-slot:connect>
        <div class="kind">
          <select name="" id="">
            <option value="">ETH</option>
            <option value="">BSC</option>
            <option value="">HECO</option>
          </select>
        </div>
      </template>
    </Head> -->
    <HomeHead></HomeHead>
    <Top></Top>
    <div class="content">
      
      <!-- <div class="swiper">
        <div class="text">
          <p class="p1">玩转 <span>DeFi</span></p>
          <p class="p2">启动 <span>FaxSwap</span></p>
          <p class="p3"><span>FAX</span> 流动性挖矿</p>
          <p class="p4">开启寻金之旅</p>
          <div>
            <span class="span1"></span>
            <span class="span2"></span>
            <span class="span2"></span>
          </div>
        </div>
        <img class="img1" src="../assets/images/home/1.png" alt="" />
      </div> -->

      <div class="dcenter">
        <div class="datatitle">
          <img src="../assets/images/mine/data.png" alt="" />
          <p>数据中心</p>
        </div>

        <div class="balance">
          <div class="fax">
            <img
              style="width: 0.56rem"
              src="../assets/images/mine/icon1.png"
              alt=""
            />
            <p>FAX 当前余额</p>
            <h2>{{ balancefax }}</h2>
          </div>
          <div class="fs">
            <img
              src="../assets/images/mine/icon2.png"
              alt=""
              style="width: 0.52rem; height: 0.52rem"
            />
            <p>FS 当前余额</p>
            <h2>{{ balancefs }}</h2>
          </div>
        </div>

        <div @click="$router.push('/airdrop')" class="income">
          <div><img src="../assets/images/mine/24.png" alt="" /></div>
          <span>收益明细</span>
        </div>

        <div class="detail">
          <div class="dbox">
            <div>
              <img src="../assets/images/mine/15.png" alt="" />
              <span>社区数量</span>
            </div>
            <p>{{ loweCount }}</p>
          </div>
          <div class="dbox">
            <div>
              <img src="../assets/images/mine/16.png" alt="" />
              <span>社区流量</span>
            </div>
            <p>{{ communityFlow }}</p>
          </div>
          <div class="dbox">
            <div>
              <img src="../assets/images/mine/17.png" alt="" />
              <span>社区算力</span>
            </div>
            <p>{{ communitySl }}</p>
          </div>
          <div class="dbox">
            <div>
              <img src="../assets/images/mine/19.png" alt="" />
              <span>每周算力增减量</span>
            </div>
            <p>{{ weekChangeSl }}</p>
          </div>
          <div class="dbox">
            <div>
              <img
                style="width: 0.5rem; margin-left: -0.03rem"
                src="../assets/images/mine/18.png"
                alt=""
              />
              <span>LP贡献值</span>
            </div>
            <p :class= '{hot:hotTrue}'>{{ contributeLp }}</p>
          </div>
          <div class="dbox">
            <div>
              <img
                style="width: 0.5rem; margin-left: -0.03rem"
                src="../assets/images/mine/20.png"
                alt=""
              />
              <span>当日算力空投量</span>
            </div>
            <p>{{ todaySl }}</p>
          </div>
        </div>

        <div class="incomeval">
          <div class="i1">
            <p>FAX算力空投</p>
          </div>
          <div class="i3">
            <p>{{ amount }}</p>
          </div>
          <div class="i2" @click="visible = true">
            <h2>提取</h2>
          </div>
        </div>

        <el-dialog
          title="确认信息"
          :visible="visible"
          width="90%"
        >
          <span>是否确认提取？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取 消</el-button>
            <el-button type="primary" @click="withDrawerSLKT"
              >确 定</el-button
            >
          </span>
        </el-dialog>
      </div>

      <div class="scan">
        <div class="qr">
          <div class="qrbox">
            <div></div>
            <img src="../assets/images/mine/ewm.png" alt="" />
          </div>
          <p>邀请中心</p>
          <div class="ewm">
            <vue-qr 
	            :size='160' 
	            :margin='0' 
	            :auto-color='true' 
	            :dot-scale='1'
	            :text='walletAddress'
	            >
            </vue-qr>
          </div>
          <h2>我的邀请地址</h2>
          <h4>{{ walletAddress }}</h4>
          <div
            @click="copyaddress"
            class="copy"
            :data-clipboard-text="walletAddress"
          >
            <img src="../assets/images/mine/copy.png" alt="" />
            点击复制邀请地址
          </div>
        </div>
        <div class="share">
          <img src="../assets/images/mine/yqx.png" alt="" />
          <p>邀请人地址</p>
          <div v-if="binded" class="ipt">
            <input
            v-model="invitAddr"
            type="text"
            />
            <img @click="$router.push('/Scanqr')" src="../assets/images/mine/scan.png" alt="" />

          </div>
          
          <div v-else>{{ invitAddr }}</div>
        </div>
        <div v-if="binded" @click="invitation" class="invit">绑定邀请</div>
        <div v-else class="invit1">已绑定</div>
    </div>
    
    </div>
    
    <Tabs :select="5"></Tabs>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import Qrcode from "qrcodejs2";
import HomeHead from "../components/homehead.vue";
import Top from "../components/top.vue";
import Tabs from "../components/tabs.vue";
import Head from "../components/head.vue";
import Clipboard from "clipboard";
import qs from "qs";
//import bech32 from "bech32";
// import { encodeAddressToBech32 } from "../util/encodeAddressToBech32";
import { convertBech32ToHex } from "../util/convertBech32ToHex";

let Web3 = require("web3");
export default {
  name: "Mine",
  components: {
    Tabs,
    HomeHead,
    Top,
    VueQr
  },
  data() {
    return {
      walletAddress: "",
      // walletAddressF1: "",
      balancefax: "",
      balancefs: "",
      //invitAddr: 'f1cm2l5ygru8hxjgn5xrxuxd26qegqql49n6nexr'
      invitAddr: "",
      binded: true,
      loweCount: '',
      communityFlow: "",
      communitySl: "",
      weekChangeSl: "",
      contributeLp: "",
      todaySl: '',
      amount: "",
      visible: false,
      hot: 'color: red',
      hotTrue: false
    };
  },
  created() {
    this.getdata();
    this.bus.$on('scan', res => {
      console.log('scanRes:', res);
      this.invitAddr = res
    })
  },
  mounted() {
    let timer =setInterval(() => {

        if(window && window.ethereum) {
          console.log('window.ethereum')
          this.getdata()
          clearInterval(timer)
        }
      }, 1000)
  },
  destroyed() {
    this.bus.$emit('detail', this.walletAddress)
  },
  methods: {
    //提取
    draw() {
      this.visible = true

    },
    //扫描
    // toScanqr() {
    //   this.$router.push('/Scanqr')
    // },
    //获取收益
    getIncomeVal() {
      this.$axios
        .post(
          "lpSlktWallet/findByAddress",
          qs.stringify({ address: this.walletAddress })
        )
        .then((res) => {
          console.log("getIncomeVal:", res);

          //社区流量
          this.communityFlow = res.data.data.communityFlow == 0 ? '-' :  res.data.data.communityFlow.toFixed(0)

          //社区算力
          this.communitySl = res.data.data.communitySl == 0 ? '-' : res.data.data.communitySl.toFixed(2)

          //每周算力增减量
          let countSl = res.data.data.currentWeekSl - res.data.data.lastWeekSl
          this.weekChangeSl = countSl == 0 ? '-' : countSl.toFixed(2)

          //LP贡献值
          // this.contributeLp = res.data.data.contributeLp == 0 ? '-' : res.data.data.contributeLp.toFixed(2)
          if(res.data.data.contributeLp < 0) {
            this.contributeLp = res.data.data.contributeLp
            this.hotTrue = true
          }
          this.contributeLp = res.data.data.contributeLp

          //FAX算力空投
          this.amount = res.data.data.amount == 0 ? '-' : res.data.data.amount.toFixed(4)

          //当日算力空投量
          let startTime = new Date('2021-08-13 00:00:00'); // 开始时间
				  let endTime = new Date(); // 结束时间
				  console.log('startime:', startTime / 1000 / 60 / 60 / 24);
				  console.log('endtime:', endTime / 1000 / 60 / 60 / 24);
				  let days = Math.floor((endTime - startTime) / 1000 / 60 / 60 / 24);
				  console.log('days:', days)
				  this.todaySl = (9727 * Math.pow(1.001,days)).toFixed(2)

        });
    },
    //提取算力空投
    withDrawerSLKT() {
      this.visible = false
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.$axios
        .post(
          "lpSlktWallet/withdrawProfit",
          qs.stringify({ address: this.walletAddress })
        )
        .then((res) => {
          console.log("withDrawerSLKT:", res);
          if (res.data.status == 200) {
            this.$message.info(res.data.message);
            loading.close();
            this.getIncomeVal()
          }
          this.$message.info(res.data.message);
          loading.close();
        });
      
    },
    //确认邀请有无关系
    invitConfirm() {
      this.$axios
        .post(
          "walletAddressRecommendInfo/check/invitation",
          qs.stringify({ lowerAddress: this.walletAddress })
        )
        .then((res) => {
          console.log("invitConfirm:", res);
          if (res.data.data.invitation) {
            this.invitAddr = convertBech32ToHex(res.data.data.address);
            this.binded = false;
            //社区数量
            this.loweCount = res.data.data.loweCount == 0 ? '-' : res.data.data.loweCount
          }
          //this.invitAddr = '请输入邀请人地址'
        });
    },
    //获取首次数据
    async getdata() {
      console.log("加载 1 " + this.walletAddress);
      //if (window && window.ethereum) {
        let web3 = new Web3(window.ethereum);
        let addres = await window.ethereum.enable();
        //console.log('addres:',addres);
        // this.walletAddressF1 = encodeAddressToBech32(addres[0]);
        this.walletAddress = addres[0];
        console.log("努力的加载 2 " + this.walletAddress);
        this.invitConfirm(); //邀请确认
        this.getIncomeVal(); //获取收益值
      //}
      // this.walletAddress='0x7e6Fe1665a2dDaCF8C0a4545b3858622C4b285D8'
      web3 = new Web3(
        new Web3.providers.HttpProvider("http://api.faxchain.org")
      );
      //获取fax余额
      web3.eth.getBalance(this.walletAddress).then((res) => {
        console.log("fax:", res);
        this.balancefax = res / Math.pow(10, 18)>0? (res / Math.pow(10, 18)).toFixed(8) : '-'
      });
      //获取fs余额
      var encodeData = web3.eth.abi.encodeFunctionCall(
        {
          inputs: [
            {
              internalType: "address",
              name: "account",
              type: "address",
            },
          ],
          name: "balanceOf",
          outputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        [this.walletAddress]
      );

      console.log("encodeData:", encodeData);

      web3.eth
        .call({
          from: this.walletAddress,
          to: "0xa178Cf8521b04dd10cec5bb1c2559e0D3D95A0e2",
          data: encodeData,
        })
        .then((res) => {
          console.log("fs:", res);
          //console.log('balance:', JSON.stringify(res));
          //console.log('balance-util:', JSON.stringify(web3.utils.fromWei(web3.eth.abi.decodeParameter("uint256",res),"ether")));
          this.balancefs = res / Math.pow(10, 18)>0? (res / Math.pow(10, 18)).toFixed(8) : '-'
        });
    },
    //复制地址
    copyaddress() {
      let clipboard = new Clipboard(".copy");
      //console.log('content:',clipboard)
      if (clipboard) {
        this.$message.success("操作成功！");
      }
    },
    //绑定邀请
    invitation() {
      if (this.invitAddr == "") {
        this.$message.error("邀请人地址不能为空！");
        return;
      }
      this.$axios
        .post(
          "/walletAddressRecommendInfo/record/recommendInfo",
          qs.stringify({
            lowerAddress: this.walletAddress, //本地钱包地址
            password: "123456", //钱包密码
            address: this.invitAddr, //邀请人地址
          })
        )
        .then((res) => {
          console.log("invitation:", res);
          if (res.data.status == 200) {
            this.$message.success(res.data.message);
            this.invitAddr = convertBech32ToHex(res.data.data.address);
            this.binded = false;
          }
          this.$message.error(res.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.content
  padding: 12px
  position: relative
  top: -70px
  .swiper
    padding-top: .6rem
    display: flex
    justify-content: center
    //width: 100%
    height: 5.2rem
    box-sizing: border-box
    background: #5F77F9
    >p
      margin: .2rem 0
    .text
      color: #fff
      .p1
        font-size: .4rem
        font-weight: lighter
        font-family: MicrosoftYaHeiLight
        span
          font-size: .55rem
          color: #FEC75C
          font-weight: 400
          font-family: Poppins-Medium
      .p2
        font-size: .4rem
        font-weight: Regular
        font-family: MicrosoftYaHei
        span
          font-size: .6rem
          font-weight: Bold
          font-family: Poppins-Bold
      .p3
        font-family: MicrosoftYaHeiBold
        font-size: .33rem
        span
          font-weight: Bold
          font-family: MicrosoftYaHeiBold
          font-size: .33rem
      .p4
        margin-top: 0.2rem
        padding: .1rem .1rem
        width: 2.2rem
        border-radius: .3rem
        font-size: .26rem
        text-align: center
        background: #F94A5E
      >div
        position: absolute
        left: 3.6rem
        top: 4.7rem
        width: 1rem
        height: 0.1rem
        display: flex
        justify-content: space-around
        .span1
          display: block
          width: .55rem
          height: 0.09rem
          background: #B9C1EA
          border-radius: .2rem
          .span2
            display: block
            width: .09rem
            height: 0.09rem
            background: #8592DA
            border-radius: .2rem
    .img1
      width: 3.3rem
      height: 3.1rem
  .dcenter
    //position: relative
    //top: -60px
    padding: .1rem 0
    margin: 0 auto .1rem
    //width: 93%
    border-radius: .2rem
    background: #F94A5E
    box-shadow: 1px 1px 5px 1px rgba(0,0,0,.3)
    .datatitle
      padding: .2rem 0
      display: flex
      justify-content: center
      align-items: center
      img
        margin-right: 0.2rem
        width: .35rem
        height: .35rem
      >p
        color: #fff
        font-size: .3rem
        font-weight: 700
        font-family: SourceHanSansCN-Light
    .balance
      padding: .06rem .3rem
      display: flex
      justify-content: space-between
      .fax, .fs
        padding: .1rem 0
        width: 49%
        background: #fff
        border-radius: .1rem
        text-align: center
        img
          width: .6rem
          height: .5rem
        >p
          padding: .1rem 0
          color: #777
          font-size: .26rem
        h2
          padding: 0 .3rem
          font-size: .28rem
          height: 0.4rem
          line-height: 0.4rem
          color: #777
          font-family: Roboto-Bold
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
      .fs
        img
          width: .7rem
          height: .7rem

    .income
      padding: .2rem 0
      display: flex
      justify-content: center
      align-items: center
      margin: .2rem .3rem .13rem
      border-radius: .1rem
      background: #5F77F9
      div
        img
          margin-right: 0.2rem
          width: .33rem
          height: .35rem
      span
        color: #fff
        font-size: .3rem
        font-weight: 700
        font-family: SourceHanSansCN-Light
  .detail
    margin: .3rem
    border-radius: .1rem
    background: #fff
    .dbox
      padding: .25rem .6rem
      display: flex
      justify-content: space-between
      align-items: center
      border-bottom: 1px solid #F8F8F8
      &:last-child
        border: none
      div
        display: flex
        align-items: center
        img
          margin-right: 0.2rem
          width: 0.4rem
          height: 0.5rem
        span
          font-size: .28rem
          color: #777
      >p
        font-size: .3rem
        color: #777
  .incomeval
    display: flex
    justify-content: space-between
    align-items: center
    overflow: hidden
    margin: 0 auto .2rem
    width: 92%
    height: 1rem
    background: #fff
    border-radius: .8rem
    .i1
      width: 40%
      height: 1rem
      text-align: center
      color: #fff
      background: #5F77F9
      >p  
        padding: 0
        line-height: 1rem

    .i2
      width: 30%
      height: 100%
      color: #fff
      display: flex
      align-items: center
      justify-content: center
      h2
        width: 1.5rem
        height: .7rem
        line-height: 0.7rem
        text-align: center
        background: #5F77F9
        border-radius: .5rem
    .i3
      width: 30%
      text-align: center
      color: #777
      >p
        padding: .1rem 0
        margin: 0 auto
        width: 90%
        text-align: center
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
  .scan
    padding: 1.4rem 0 2rem
    margin: .3rem auto
    //width: 93%
    //height: 25rem
    border-radius: .2rem
    background: #5F77F9
    .qr
      padding: .2rem 0
      //height: 9rem
      position: relative
      margin: 0 auto
      width: 85%
      border-radius: .2rem
      background: #EFF2FE
      .qrbox
        //height: 10rem
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        left: 50%
        transform: translate(-50%, -60%)
        width: 1.6rem
        height: 1.6rem
        border-radius: .8rem
        background: #5063CA
        img
          width: .8rem
          height: .8rem
      >p
        padding-top: 1rem
        text-align: center
        font-size: .3rem
        color: #777
      .ewm
        margin: .5rem auto
        width: 3.6rem
        height: 3.6rem
        background-image: url('../assets/images/mine/kuang.png')
        background-size: 3.6rem 3.6rem
        display: flex
        justify-content: center
        align-items: center
        
        // .creatqr
        //   padding-top: 0.18rem
        //   margin: 0 auto
        //   width: 3.2rem
        //   height: 3.2rem
        //   display: flex
          // justify-content: center
          // align-items: center

      h2
        text-align: center
        font-size: .3rem
        color: #777
      h4
        margin: .4rem 0
        text-align: center
        font-size: .2rem
        color: #777
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
      .copy
        margin: 0 auto .3rem
        padding: .18rem 0
        width: 60%
        display: flex
        justify-content: center
        align-items: center
        text-align: center
        background-image: linear-gradient(-90deg, #33CBFF, #6679FF)
        color: #fff
        font-family: SourceHanSansCN-Light
        font-size: .28rem
        border-radius: .5rem
        img
          margin-right: 0.2rem
          width: 0.3rem
          height: 0.3rem
    .share
      margin: .3rem auto
      width: 85%
      height: 3rem
      border: 1px solid #AEBBFC
      border-radius: .2rem
      color: #fff
      text-align: center
      img
        display: block
        margin: .4rem auto .3rem
        width: 0.7rem
        height: 0.7rem
      >p
        font-size: .3rem
      .ipt
        display: flex
        justify-content: space-between
        align-items: center
        input
          //padding: 0 .1rem
          width: 90%
          //margin-top: 0.2rem
          height: 0.8rem
          text-align: center
          color: #777
          font-size: .2rem
          border-radius: .1rem
          overflow: hidden
          text-overflow: ellipsis
          white-space: nowrap
        img
          width: 0.8rem
          height: 0.8rem
      div
        padding: 0 .2rem
        width: 90%
        margin: 0.2rem auto 0
        //margin: .2rem .5rem
        //padding: .2rem 0
        height: 0.8rem
        line-height: 0.8rem
        text-align: center
        color: #777
        font-size: .2rem
        border-radius: .1rem
        background: #fff
        //border-top: 1px solid #AEBBFC
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
    .invit
      width: 85%
      margin: 0 auto
      padding: .2rem 0
      background: #fff
      color: #5F77F9
      font-size: .31rem
      text-align: center
      font-family: SourceHanSansCN-Light
      border-radius: .5rem
    .invit1
      width: 85%
      margin: 0 auto
      padding: .2rem 0
      background: #bbb
      color: gray
      font-size: .31rem
      text-align: center
      font-family: SourceHanSansCN-Light
      border-radius: .5rem
</style>